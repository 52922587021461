.Modal {
  &-Watcher {
    border: 1px solid var(#{$prefix}blue-light);
    max-width: 680px;
    position: fixed;
    width: 90%;
    @include tablet {
      min-width: 700px;
    }

    .Modal-Close {
      fill: $white;
    }

    &-Header {
      @include flex-container(row, center);
      background: var(#{$prefix}blue-light);
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      cursor: default;
      font-size: rem(18px);
      font-weight: $font-weight-bold;
      height: 55px;
      padding: 0 20px;

      p {
        @include truncate(calc(100% - 65px));
        margin: 0 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &-Body {
      align-items: center;
      background: var(#{$prefix}blue-light);
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      display: flex;
      justify-content: center;
      min-height: 400px;
      padding: 0 10px 25px;
      position: relative;

      img {
        border-radius: 4px;
        height: auto;
        max-height: 85vh;
        width: auto;
      }

      iframe {
        background: $white;
        border-radius: 4px;
        height: 60vh;
        width: 100%;
      }
    }
  }
}
