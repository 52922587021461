.HintPassword {
  .Settings {
    &-Select {
      align-items: flex-start;
      flex-direction: column;
      margin: 0 0 10px;

      label{
        margin-bottom: 5px;
      }
    }

    &-PassSelect {
      min-width: 170px;
    }

    &-Switch {
      margin: 10px 0;

      p{
        margin-left: 10px;
      }
    }
  }
}
