.ForgotPassword {
  height: 100%;
  min-height: calc(100vh - 100px);
  padding: 0;
  width: 100%;

  @include tablet {
    min-height: calc(100vh - 60px);
  }

  @include fullhd {
    min-height: calc(100vh - 70px);
  }

  &-Close {
    position: absolute;
    right: 14px;
    top: 20px;

    svg {
      fill: $gray-03;
    }
  }

  p {
    color: $gray-04;
    font-size: rem(12px);
    margin: 20px 0;
    padding: 0;
    text-align: center;
  }

  // TODO: Combine helper OTP and Forgot popup style
  &-Otp {
    position: relative;

    &-Open {
      background-color: var(#{$prefix}blue-dark);
      border-radius: $border-radius;
      left: 0;
      max-width: 300px;
      position: absolute;
      top: 35px;
      width: 100%;
      z-index: $zlayer-2;

      &::before {
        background-color: var(#{$prefix}blue-dark);
        content: "";
        height: 10px;
        position: absolute;
        right: 40px;
        top: -5px;
        transform: rotate(45deg);
        width: 10px;
        @include mobile {
          right: calc(50% - 5px);
        }
      }

      p {
        color: $white;
        line-height: 16px;
        margin: 0;
        padding: 15px;
        text-align: left;

        a {
          color: $white;
          font-weight: bold;
        }
      }

      @include mobile {
        left: auto;
        right: -34%;
        top: 35px;
      }
    }
  }

  &-ForgotHelp {
    background-color: var(#{$prefix}blue-dark);
    border-radius: $border-radius;
    left: 95%;
    max-width: 300px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    z-index: $zlayer-2;

    &::before {
      border-color: transparent var(#{$prefix}blue-dark) transparent transparent;
      border-style: solid;
      border-width: 5px 5px 5px 0;
      content: "";
      height: 0;
      left: -5px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      width: 0;
    }

    p {
      color: $white;
      line-height: 16px;
      margin: 0;
      padding: 15px;
      text-align: left;
      white-space: break-spaces;

      a {
        color: $white;
        font-weight: bold;
      }
    }
  }

  &-Header {
    align-items: center;
    display: flex;
    height: 60px;
    justify-content: space-between;
    padding: 0 20px;
    width: 100%;

    &Icon {
      svg {
        width: 230px;
        @include mobile {
          width: 300px;
        }
      }

      img {
        max-height: 35px;
      }
    }

    &Language {
      svg {
        height: 20px;
        width: 20px;
        @include mobile {
          height: 24px;
          width: 24px;
        }
      }
    }

    @include mobile {
      height: 70px;
      padding: 0 20px;
    }
    @include fullhd {
      height: 100px;
      padding: 0 30px;
    }
  }

  &-Background {
    align-items: center;
    background-image: url("#{$assetPath3}/png/bg-login.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    min-height: calc(100vh - 100px);
    width: 100%;

    @include mobile {
      border-radius: 12px;
      min-height: calc(100vh - 120px);
    }

    @include tablet {
      min-height: calc(100vh - 160px);
    }

    @include fullhd {
      min-height: calc(100vh - 200px);
    }
  }

  &-Footer{
    .Footer-Content{
      padding: 0 20px;
      @include fullhd {
        padding: 0 40px;
      }
    }
  }

  &-Form {
    background: $white;
    border-radius: $border-radius-lg;
    box-shadow: $box-shadow;
    max-width: 340px;
    min-width: 340px;
  }

  &-Error {
    color: $red;
    padding: 0 0 10px;
  }

  &-FieldsContainer {
    padding: 0 20px;

    .Field {
      &-Checkbox {
        align-items: center;
        flex-direction: row-reverse;
        justify-content: flex-end;
        margin-bottom: 20px;

        .Label {
          font-size: rem(14px);
          margin: 0 0 0 10px;
          padding-top: 3px;
        }
      }
    }

    @include mobile {
      padding: 0 30px;
    }
  }

  &-FieldGroup {
    position: relative;

    .ForgotPassword-Error {
      margin-bottom: -10px;
      padding: 5px 0 0;
    }

    .Field {
      &:nth-child(2) {
        margin-top: -1px;
      }

      &-Input {
        // &_hasError {
        //   input {
        //     border: 1px solid $red !important;
        //   }
        // }

        input {
          border: 1px solid var(#{$prefix}blue-dark);
        }
      }
    }

    &Input {
      &Flex {
        .Label {
          align-items: center;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }

  // &-FieldGroupInput_has-error {
  //   input {
  //     border: 1px solid $red !important;
  //   }
  // }

  &-FormHeader {
    color: $black;
    font-size: rem(16px);
    font-weight: $font-weight-bold;
    font-weight: bold;
    padding: 10px 0;
    text-align: left;
  }

  &-ButtonContainer {
    align-items: center;
    background-color: $gray-01;
    border-radius: 0 0 $border-radius-lg $border-radius-lg;
    display: flex;
    justify-content: space-between;
    padding: 16px 20px;
    width: 100%;

    @include mobile {
      padding: 16px 30px;
    }
  }

  &-Forgot {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    padding: 0 20px !important;
    position: relative;
    @include mobile {
      padding: 0 30px !important;
    }
  }

  &-FieldGroup {
    margin: 0 0 20px;

    .Messages-Error {
      bottom: -18px;
      font-size: rem(12px);
      margin: 0;
      position: absolute;
      right: 6px;
    }

    .Field {
      margin: 0;
    }
  }

  @include mobile {
    padding: 0 20px;
  }

  &-ActionDelimiter {
    @include flex-container();

    margin: 10px 0;

    .line {
      background: $gray-04;
      flex: 1;
      height: 1px;
    }

    .or {
      color: $gray-04;
      font-size: rem(12px);
      margin: 0 12px;
    }
  }

  &-btnAzure {
    background: $white;
    border: 1px solid var(#{$prefix}blue-dark);
    border-radius: $border-radius;
    color: var(#{$prefix}blue-dark);
    font-size: rem(14px);
    font-weight: $font-weight-bold;
    height: 40px;
    text-align: center;
    width: 100%;

    svg {
      @include middle-inline-block();
      margin-right: 10px;
    }
  }
}
