.InfoBoxUserSecurity {
  display: block;

  &-LabelSwitch {
    @include flex-container(row, space-between)
  }

  .Label{
    flex: 1 1;
    margin-left: 0;
  }

  &-Switch {
    align-items: flex-start;
  }

  &-Submit {
    bottom: 20px;
    left: 20px;
    position: absolute;
  }

  &-NotError{
    .Field-InputWrapperButton {
      display: flex;
    }
  }
}