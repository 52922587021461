.Users-Select {
  @include flex-container(row, flex-start);

  span {
    display: block;
    @include truncate(90%);
  }

  svg {
    fill: var(#{$prefix}blue-dark);
    margin-right: 5px;
    min-width: 20px;
  }
}

.Users {
  min-height: calc(100% - 90px);

  &-MobileMenu {
    display: block;
    margin-bottom: 4px;
    @include widescreen {
      display: none;
      margin-bottom: 0;
    }

    .Users-ButtonAdd {
      background-color: var(#{$prefix}blue-dark);
      border: 1px solid transparent;
      border-radius: 6px;
      color: $white;
      font-weight: bold;
      height: 36px;
      line-height: 1;
      min-height: auto;
      min-width: auto;
      padding: 8px 18px;
      white-space: nowrap;
      @include tablet {
        height: 40px;
        min-width: 125px;
        padding: 13px 23px;
      }
    }
  }

  .Mobile-In {
    display: block;
    @include tablet {
      display: none;
    }
  }

  .Mobile-Out {
    display: none;
    @include tablet {
      display: block;
    }
  }

  &-Action {
    @include flex-container(row, flex-start);
    margin-top: 20px;
  }

  .SearchInput {
    flex: 1 1 auto;
    margin: 10px 0;
    width: 100%;

    &-Input {
      min-width: 0;
    }

    @include tablet {
      margin: 0 10px 0 0;
      min-width: 120px;
      width: auto;
    }
  }

  .Search-Close {
    padding: 0;

    @include tablet {
      padding: 0 20px 0 0;
    }
  }
}
