.Unsubscribe {
  align-items: center;
  animation: modalOpen 0.2s ease-in-out;
  backdrop-filter: blur(10px);
  background-color: rgba(var(#{$prefix}blue-dark), .3);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10;

  &-Modal {
    background-color: $white;
    border-radius: 5px;
    box-shadow: 2 0 0 $black;
    height: auto;
    margin: 0 16px;
    max-width: 600px;
    padding: 20px;
    width: 100%;
  }

  &-Actions {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0;
    @include mobile {
      padding: 0 30px;
    }
  }

  &-Header {
    align-items: center;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    @include mobile {
      font-size: 18px;
    }

    svg {
      margin-right: 12px;
      min-width: 18px;
    }
  }

  &-Content {
    padding: 15px 0;
    @include mobile {
      padding: 10px 30px;
    }
  }

  p {
    margin-bottom: 15px;
  }

}
