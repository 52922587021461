.KeysBlock {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  margin-left: 0;
  width: 100%;
  @include desktop {
    margin-left: -20px;
    width: calc(100% + 20px);
  }

  &-Checkbox {
    padding: 5px;
  }

  &-Container {
    min-height: calc(100% - 90px);
    position: relative;
    width: 100%;
  }

  &-Sharing {
    fill: #A7A7A8;

    &_isSharing {
      fill: var(#{$prefix}blue-dark);
    }
  }

  .key-logo {
    border: 1px solid $gray-01;
    border-radius: 6px;
    height: 100%;
    object-fit: cover;
    position: absolute;
    width: 100%;
  }

  .sealingIconBlock {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 4px;

    svg {
      fill: var(#{$prefix}blue-dark);
    }
  }

  .sealingIcon {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: -1px;
    margin-left: 5px;
  }

  &-Actions {
    align-items: center;
    display: none !important;
    justify-content: flex-end;
    padding: 0 10px;
    width: 65px;

    &Clear,
    a,
    button {
      align-items: center;
      display: flex;
      height: auto;
      justify-content: center;
      padding: 1px 6px;
      width: 30px;
    }

    &Clear {
      cursor: default;
    }

    button {
      color: $gray-02;

      &:hover {
        color: var(#{$prefix}blue-light);

        svg {
          fill: var(#{$prefix}blue-light);
          @extend %transition;
        }

        @extend %transition;
      }

      @extend %transition;
    }

    svg {
      fill: $gray-02;

      &:hover {
        color: var(#{$prefix}blue-light);
        fill: var(#{$prefix}blue-light);
        @extend %transition;
      }

      @extend %transition;
    }

    @include tablet {
      display: flex !important;
      justify-content: space-between;
      width: 150px;
    }
  }

  &-Item {
    align-items: center;
    background-color: transparent;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    padding: 15px 20px;
    position: relative;
    width: 50%;

    @include tablet {
      width: 33%;
    }

    @include keyblock {
      width: 20%;
    }

    @include fullhd {
      width: 16.6%;
    }

    &_chosen,
    &:hover {
      background-color: $gray-01;
      @extend %transition;

      .KeysBlock-Item-Image {
        &::before {
          border-right: 24px solid $gray-01;
          border-top: 24px solid $gray-01;
          @extend %transition;
        }
      }
    }

    &-Image {
      align-items: center;
      background-color: var(#{$prefix}blue-01);
      border-radius: 6px;
      display: flex;
      height: 100%;
      justify-content: center;
      position: relative;
      width: 100%;

      &::after {
        content: "";
        display: block;
        padding-top: 100%;
      }

      &:hover {
        .KeysBlock-Item-Image-Actions {
          opacity: 1;
          @extend %transition;
        }

        .KeysBlock-Item-Image-Icon {
          opacity: 0;
          @extend %transition;
        }
      }

      &-Text {
        color: var(#{$prefix}blue-dark);
        font-size: 34px;
        font-weight: bold;
        margin: 0;
        text-transform: uppercase;
      }

      &-Checkbox {
        left: 5px;
        position: absolute;
        top: 5px;
      }

      &-Icon {
        align-items: center;
        background: $white;
        border-top-left-radius: 6px;
        bottom: 0;
        display: flex;
        height: 20px;
        justify-content: center;
        opacity: 1;
        position: absolute;
        right: 0;
        width: 20px;
        @extend %transition;

        svg {
          fill: var(#{$prefix}blue-dark);
        }
      }

      &-Favorites {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 2px;
        top: 25px;

        button {
          margin: 0 0 5px;
          padding: 0;

          svg {
            fill: var(#{$prefix}blue-dark);
          }
        }
      }

      &-Share {
        bottom: 20px;
        position: absolute;
        right: -20px;

        button {
          margin: 0;
          padding: 0;
        }
      }

      &-Actions {
        align-items: center;
        background-color: rgba($white, .9);
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        bottom: 0;
        display: flex;
        height: auto;
        justify-content: space-between;
        opacity: 0;
        padding: 6px 5px;
        position: absolute;
        width: 100%;

        &-Box {
          align-items: center;
          display: flex;
          justify-content: space-between;
          padding: 0 5px;
          width: 100%;

          .KeysList-Action-Item{
            padding: 0;
          }

          .Actions-Item {
            align-items: center;
            display: flex;
            justify-content: center;
            max-width: 20px;
          }

          button {
            color: var(#{$prefix}blue-dark);
            font-size: 9px;
            padding: 0;

            svg {
              fill: var(#{$prefix}blue-dark);
              width: 12px;
            }
          }

          svg {
            fill: var(#{$prefix}blue-dark);
          }
        }

        @extend %transition;
      }
    }

    &-Info {
      display: flex;
      flex-direction: column;
      // max-width: 100px;
      padding-top: 5px;
      width: 100%;

      p {
        @include truncate(100%);
        color: $black;
        font-size: rem(13px);
        margin: 0;
      }

      span {
        color: $gray-04;
        font-size: rem(12px);
      }
    }
  }
}
