.ModalSelectTree {
  &-Folder {
    @include flex-container(row, flex-start);

    height: 50px;
    width: 100%;

    &:hover {
      background: var(#{$prefix}blue-01);
    }

    &_isOpen {
      background: var(#{$prefix}blue-01);
      // margin: 0 0 10px 0;
    }

    &_isActive,
    &_isActiveMulti {
      background: var(#{$prefix}blue-01);
      font-weight: bold;
    }

    &_isExistFolder,
    &_isNotFolderTree {
      background: $gray-01;

      &:hover {
        background: var(#{$prefix}blue-01-lightness-5) !important;
      }
    }
  }

  &-No_access {
    filter: grayscale(100%);
  }

  .EmptyFolderStatus {
    min-width: 36px;
  }

  .FolderStatus {
    margin: 0 10px;
  }

  .FolderIcon {
    margin: 0 10px 0 0;
    min-width: 30px;
  }

  &-Title {
    flex: 1;
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-Count {
    color: $gray-04;
    font-size: rem(10px);
    font-weight: $font-weight-normal;
  }

  @for $i from 1 through 10 {
    &_level_#{$i} {
      @extend %tree-level;
    }
  }
}
