.HeaderNavigation {
  align-items: flex-end;
  border-bottom: 1px solid rgba($gray-03, .4);
  display: flex;
  margin-right: 10px;
  position: relative;
  width: 100%;

  &-Link {
    align-items: flex-end;
    box-sizing: border-box;
    color: $gray-04;
    display: flex;
    font-size: rem(14px);
    height: 28px;
    justify-content: center;
    line-height: 1;
    margin: 0;
    min-width: 60px;
    padding: 0 10px 8px;
    position: relative;

    @include tablet {
      min-width: 110px;
    }

    &::after {
      background: transparent;
      border-radius: $border-radius;
      bottom: -2px;
      height: 3px;
      width: 100%;
      @include pseudo();
      @extend %transition;
    }

    &:last-child {
      margin: 0;
    }

    &:hover {
      &::after {
        background: var(#{$prefix}blue-dark);
      }
    }

    svg {
      margin: 0;
      width: auto;
      @extend %transition;
      @include tablet {
        margin: 0 10px 0 0;
      }
    }

    span {
      display: none;
      @include tablet {
        display: inline;
      }
    }

    @extend %transition;
  }

  &-isActive {
    color: $black;
    font-weight: $font-weight-bold;

    &::after {
      background: var(#{$prefix}blue-dark);
    }

    svg {
      fill: var(#{$prefix}blue-dark);
    }
  }
}
