.InfoBoxActivityLog {
  &-Detail {
    margin-bottom: 20px;
  }

  &-Label {
    font-size: rem(12px);
    margin-bottom: 3px;
  }

  &-History {
    @include flex-container(row, flex-start, center, stretch);

    margin: 5px 0;

    .line {
      align-self: stretch;
      background: $gray-03;
      margin: 5px 10px 5px 5px;
      position: relative;
      width: 2px;

      &::before {
        @include pseudo();
        background: $gray-03;
        height: 10px;
        line-height: 0;
        right: -4px;
        transform: rotateZ(-45deg);
        width: 10px;

      }

      &::after {
        @include pseudo();
        border-bottom: 2px solid $gray-03;
        border-left: 2px solid $gray-03;
        bottom: 0;
        height: 10px;
        right: -4px;
        transform: rotateZ(-45deg);
        width: 10px;
      }
    }

    .info {
      .from {
        margin-bottom: 10px;
      }
    }
  }

  &-Value {
    @include flex-container(row, flex-start);
    margin: 5px 0;

    &.blue {
      color: var(#{$prefix}blue-dark);
    }

    &.bold {
      font-weight: $font-weight-bold;
    }

    .icon {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 0 10px 0 0;

      svg {
        fill: var(#{$prefix}blue-dark);
      }
    }
  }

  &-ValueList {
    margin: 0;
    padding: 0;

    li {
      font-weight: $font-weight-bold;
    }
  }
}
