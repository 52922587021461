@import "src/styles/mixins/media";

.Layout {
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  min-height: 100dvh;
  width: 100%;

  &-Container {
    display: flex;
    height: calc(100vh - 110px);
    margin: 0 auto;
    max-width: 1920px;
    overflow: auto;
    width: 100%;
    @include tablet {
      height: calc(100vh - 120px);
    }
    @include desktop {
      height: calc(100vh - 130px);
    }
    @include fullhd {
      height: calc(100vh - 150px);
    }
  }
}

.tooltip {
  cursor: pointer;
  display: flex;
  position: relative;

  &::after {
    background: var(#{$prefix}blue-dark);
    border-radius: 8px 8px 8px 0;
    color: $white;
    content: attr(data-tooltip);
    display: none;
    font-size: rem(12px);
    font-weight: normal;
    left: 105%;
    line-height: 16px;
    opacity: .9;
    padding: 2px 7px;
    position: absolute;
    top: calc(50% - 20px);
    white-space: nowrap;
    z-index: 1;
  }

  &:hover {
    &::after {
      animation: fadeInShow .6s;
      display: block;
    }
  }

  &-left {
    &::after {
      border-radius: 8px 8px 0;
      left: auto;
      right: 105%;
    }
  }

  &-sealing {
    &::after {
      background: var(#{$prefix}blue-dark);
      border-radius: 8px 8px 0;
      left: -250px;
      top: -300%;
      white-space: pre-wrap;
      width: 250px;
      @include tablet {
        background: var(#{$prefix}blue-dark);
        border-radius: 8px 8px 8px 0;
        left: 105%;
        right: 105%;
        width: 300px;
      }
    }
  }
}

.Toastify {
  &__close-button {
    display: none;
  }

  &__toast {
    border-radius: $border-radius;
    color: $black;
    filter: drop-shadow(0 5px 15px rgba(0, 0, 0, 0.16));
    font-size: rem(14px);
    max-width: 350px;
    min-height: 55px;
    width: 100%;

    &--default {
      background: adjust-color($blue-light, $lightness: 15);
    }

    &--info {
      background: adjust-color($blue-light, $lightness: 15);
    }

    &--success {
      background: adjust-color($green, $lightness: 40);
    }

    &--warning {
      background: adjust-color($yellow, $lightness: 40);
    }

    &--error {
      background: adjust-color($red, $lightness: 40);
    }
  }
}

.fakePassport {
  border: 0 !important;
  box-shadow: none !important;
  height: 0 !important;
  padding: 0 !important;
  position: fixed !important;
  width: 0 !important;
}
