.Header {
  height: 60px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;

  @include desktop {
    height: 70px;
  }

  @include fullhd {
    height: 80px;
  }

  &::after {
    background-color: var(#{$prefix}blue-01);
    content: "";
    height: 60px;
    position: absolute;
    right: 0;
    top: 0;
    width: 20%;
    z-index: 0;

    @include desktop {
      height: 70px;
    }

    @include fullhd {
      height: 80px;
    }

  }

  &-Content {
    align-items: center;
    display: flex;
    margin: 0 auto;
    max-width: 1920px;
    padding: 0 20px 0 0;
    width: 100%;
    @include desktop {
      padding: 0 20px;
    }
    @include fullhd {
      padding: 0 40px;
    }
  }

  &-Logo {
    align-items: center;
    display: none;
    height: 60px;
    justify-content: center;
    margin-right: 20px;
    max-width: 280px;
    min-width: 280px;
    width: 100%;

    svg {
      height: auto;
      margin-top: 5px;
      max-width: 300px;
      width: 100%;
      @include fullhd {
        max-width: 340px;
      }

    }

    img {
      max-height: 35px;
      @include desktop {
        display: flex;
        max-height: 50px;
      }
    }

    @include desktop {
      display: flex;
      height: 70px;
    }
    @include fullhd {
      height: 80px;
      max-width: 340px;
      min-width: 340px;
    }
  }

  &-Info {
    align-items: center;
    background-color: var(#{$prefix}blue-01);
    display: flex;
    height: 60px;
    overflow: hidden;
    padding: 10px 0 10px 50px;
    position: relative;
    width: 100%;
    z-index: 1;
    @include desktop {
      border-bottom-left-radius: 6px;
      display: flex;
      height: 70px;
      padding: 10px 0 10px 25px;
    }
    @include fullhd {
      height: 80px;
    }
  }

  &-PageTitle {
    align-items: center;
    display: flex;
    overflow: hidden;
    width: 100%;

    &-Icon {
      display: none;
      margin-right: 15px;

      svg {
        fill: var(#{$prefix}blue-dark);
      }

      @include tablet {
        display: flex;
      }
    }

    &-Name {
      font-size: 18px;
      font-weight: $font-weight-bold;
      max-width: calc(100% - 25px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @include tablet {
        font-size: 20px;
        max-width: calc(100% - 80px);
      }
    }
  }

  &-Favorite {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-left: 0;
    margin-right: 10px;
    min-width: 25px;
    position: relative;
    z-index: 1;

    svg {

      fill: var(#{$prefix}blue-dark);
      margin-bottom: 0;
      min-height: 30px;
    }

    &-Badge {
      color: var(#{$prefix}blue-dark);
      font-size: 10px;
      font-weight: bold;
      left: calc(100% - 8px);
      line-height: 10px;
      padding: 0;
      position: absolute;
      top: 1px;
    }
  }

  &-Profile {
    align-items: center;
    background-color: var(#{$prefix}blue-01);
    display: flex;
    height: 60px;
    max-width: 29px;
    min-width: 29px;
    padding: 0;
    width: 100%;
    z-index: 1;

    @include tablet {
      max-width: 170px;
      min-width: 170px;
    }

    @include desktop {
      padding: 0;
    }

    @include widescreen {
      height: 70px;
      max-width: 340px;
      min-width: 340px;
      padding: 0 20px;
    }

    @include fullhd {
      height: 80px;
      max-width: 320px;
      min-width: 320px;
      padding: 0 20px;
    }

    &-Content {
      align-items: center;
      background-color: transparent;
      border-radius: 38px;
      box-shadow: none;
      display: flex;
      height: 100%;
      height: 30px;
      padding: 2px;
      width: 100%;

      &:hover {
        background-color: $white;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);

        button {
          opacity: 1;
          @extend %transition;
        }

        @extend %transition;
      }

      button {
        display: none;
        opacity: 0;

        &:hover {
          svg {
            fill: $red;
          }
        }

        svg {
          fill: var(#{$prefix}blue-dark);
        }
        @extend %transition;

        @include tablet {
          display: flex;
        }
      }

      @extend %transition;

      @include tablet {
        display: flex;
      }

      @include widescreen {
        height: 40px;
        padding: 6px;
      }
    }

    &-Avatar {
      img {
        border-radius: 50%;
        height: 25px;
        min-width: 25px;
        overflow: hidden;
        width: 25px;
        @include widescreen {
          height: 30px;
          min-width: 30px;
          width: 30px;
        }
      }

      svg {
        fill: var(#{$prefix}blue-dark);
      }
    }

    &-Title {
      display: none;
      flex-direction: column;
      overflow: auto;
      padding-left: 5px;
      width: calc(100%);
      @include tablet {
        display: flex;
      }
      @include widescreen {
        padding-left: 10px;
      }
    }

    &-Name {
      color: $black;
      font-size: 12px;
      font-weight: $font-weight-bold;
      line-height: 12px;
      @include truncate(100%);
      @include widescreen {
        font-size: 14px;
        line-height: 16px;
      }
    }

    &-Role {
      color: $gray-04;
      font-size: 10px;
      line-height: 12px;
      @include truncate(100%);
      @include widescreen {
        font-size: 12px;
        line-height: 16px;
      }
    }

  }
}

.HeaderSearch {
  align-items: center;
  display: flex;
  height: 40px;
  left: calc(100% - 80px);
  position: absolute;
  width: auto;

  input {
    background: transparent;
    border: 0;
    border-radius: $border-radius;
    height: 35px;
    outline: none;
    padding: 0 35px;
    width: 35px;
  }

  &-Clear {
    display: none;
  }

  button {
    padding-left: 3px;
    padding-right: 0;
    @include tablet {
      padding-right: 6px;
    }
  }

  &-Button {
    align-items: center;
    display: flex;
    justify-content: center;
    left: 7px;
    position: absolute;
    top: 52%;
    transform: translateY(-50%);
    @extend %transition;

    svg {
      fill: var(#{$prefix}blue-dark);
    }
  }

  &-Clear {
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);

    &:hover {
      svg {
        fill: $red;
      }
    }

    svg {
      @extend %transition;
    }

    @include widescreen {
      right: 7px;
    }
  }

  @extend %transition;
  @include tablet {
    width: calc(100% - 85px);
  }

}

.HeaderSearch_isOpen {
  background-color: transparent;
  left: 45px;
  width: calc(100% - 90px);

  input {
    background: $white;
    border-radius: 6px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    width: 100%;
    @extend %transition;
  }

  button {
    svg {
      width: 16px;
    }
  }

  @extend %transition;

  @include desktop {
    left: 25px;
    width: calc(100% - 75px);
  }
}
