.InfoBox {
  align-items: flex-start;
  animation: modalOpen 0.2s ease-in-out;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 0;
  display: flex;
  height: auto;
  justify-content: center;
  left: 0;
  margin-bottom: 0;
  min-height: 100vh;
  overflow: inherit;
  padding: 40px 10px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;

  @include widescreen {
    background: $white;
    border-radius: $border-radius;
    display: block;
    //height: auto;
    height: 100%;
    margin: 0 0 20px;
    max-height: calc(100% - 15px);
    min-height: auto;
    padding: 0;
    position: sticky;
    right: 20px;
    top: 20px;
    width: 100%;
  }
  @include fullhd {
    max-width: 100%;
  }

  &-ButtonGroups{
    align-items: center;
    display: flex;

    button {
      &:first-child{
        margin-right: 10px;
      }
    }
  }

  &-Container {
    border-radius: 6px;
    max-height: 90vh;
    max-width: 500px;
    overflow: hidden;
    width: 100%;
    @include widescreen {
      box-shadow: $box-shadow;
      display: flex;
      flex-direction: column;
      //max-height: auto;
      max-height: 100%;
    }
  }

  &-Header {
    @include flex-container(row, flex-start);
    background-color: $gray-01;
    border-radius: 6px 6px 0 0;
    color: $black;
    font-size: rem(14px);
    font-weight: $font-weight-bold;
    height: 50px;
    padding: 15px 18px;
    position: relative;
    z-index: 1;

    svg {
      fill: var(#{$prefix}blue-dark);
      min-width: 20px;
    }

    .FolderIcon {
      svg {
        min-width: auto;
      }
    }

    &-Title {
      margin: 0 20px 0 10px;
      @include truncate(100%);
    }
  }

  &-Close {
    cursor: pointer;
    display: flex;
    fill: var(#{$prefix}blue-dark);
    padding: 0;
    position: absolute;
    right: 15px;
    top: calc(50% - 7px);
    @extend %transition;

    &:hover {
      fill: $red;
    }
  }

  &-Tabs {
    @include flex-container(row, flex-start, center, stretch);
    background-color: $gray-01;
    height: 40px;
    min-height: 40px;
    overflow: hidden;
    padding: 0 18px;
    position: relative;
    z-index: 1;

    &-Item {
      border-radius: $border-radius $border-radius 0 0;
      color: $gray-04;
      font-size: rem(14px);
      padding: 15px 15px 10px;
      position: relative;
      // font-weight: $font-weight-bold;

      &::before {
        background: transparent;
        border-radius: $border-radius;
        height: 3px;
        left: 5px;
        right: 5px;

        top: 5px;
        @include pseudo();
        @extend %transition;
      }

      &:hover {
        background-color: $white;
        color: $black;
      }

      &_isActive {
        background-color: $white;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
        color: $black;

        &::before {
          background: var(#{$prefix}blue-dark);
        }
      }

      @extend %transition;
    }
  }

  &-Logo {
    align-items: center;
    background-color: var(#{$prefix}blue-01);
    border-radius: 4px;
    display: flex;
    height: 30px;
    justify-content: center;
    min-width: 30px;
    position: relative;

    &-Image {
      border-radius: 4px;
      height: 30px;
      width: 30px;
    }

    &-Text {
      color: var(#{$prefix}blue-dark);
      font-size: 10px;
      font-weight: bold;
      margin: 0;
      text-transform: uppercase;
    }
  }

  &-Content {
    background: $white;
    flex: 1;
    height: 100%;
    max-height: 50vh;
    overflow: auto;
    padding: 15px 18px;
    @extend %scrollbar;
    @include widescreen {
      max-height: none;
    }
  }

  &-Actions {
    background-color: $gray-01;
    border-radius: 0 0 6px 6px;
    bottom: 0;
    flex-wrap: wrap;
    padding: 18px;
    position: sticky;
    @include flex-container(row, space-between);

    &-Column {
      flex-direction: column;
    }

    &-Row {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;

      &_isMargin {
        margin-top: 15px;
      }
    }
  }

  &-Error {
    color: $red;
    margin-bottom: 10px;
  }

  &-ContentHeader {
    font-size: rem(14px);
    margin: 0 0 10px;
  }

  &-Search {
    .SearchInput {
      &-Wrap {
        width: 100%;
      }

      &-Icon {
        height: 16px;
        left: 10px;
        top: 50%;
        width: 16px;

        svg {
          height: 16px;
          width: 16px;
        }
      }

      &-Input {
        background-color: $white;
        height: 38px;
        padding-left: 35px;
        width: 100%;
      }

      &-Button {
        svg {
          height: 16px;
          width: 16px;
        }
      }
    }
  }

  &-List {
    padding-left: 8px;
    padding-top: 15px;

    &-Group {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      &-Title {
        align-items: center;
        display: flex;
        max-width: calc(100% - 80px);

        svg {
          fill: var(#{$prefix}blue-dark);
          min-width: 20px;
        }

        span {
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 0;
          margin-left: 10px;
          @include truncate(100%);
        }
      }

      &-Info {
        align-items: center;
        display: flex;
      }

      &-Folder,
      &-Member {
        align-items: center;
        background-color: $white;
        border: 1px solid var(#{$prefix}blue-dark);
        border-radius: 6px;
        display: flex;
        margin: 0 3px;
        margin-right: 5px;
        padding: 4px;

        svg {
          height: 14px;
          width: 14px;
        }

      }

      &-Member {
        align-items: center;
        display: flex;

        span {
          color: var(#{$prefix}blue-dark);
          font-size: 12px;
          line-height: 14px;
        }

        svg {
          fill: var(#{$prefix}blue-dark);
        }
      }

      .groups_access_write {
        border: 1px solid $green;
      }

      .groups_access_read {
        border: 1px solid $yellow;
      }

      .groups_access_no_access {
        border: 1px solid $red;
      }
    }

    &-Item {
      align-items: center;
      display: flex;
      margin-bottom: 10px;

      svg {
        fill: var(#{$prefix}blue-dark);
        min-width: 20px;
      }

      span {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 0;
        margin-left: 10px;
        @include truncate(100%);
      }
    }
  }

  .GroupErrorList {
    margin-top: 10px;
  }

}
