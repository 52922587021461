.LayoutAnonymous {
  height: 100%;
  min-height: calc(100vh - 100px);
  padding: 0;
  width: 100%;

  @include tablet {
    min-height: calc(100vh - 60px);
  }

  @include fullhd {
    min-height: calc(100vh - 70px);
  }

  &-Header {
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1920px;
    padding: 0 20px;
    width: 100%;

    &Icon {
      display: flex;

      svg {
        width: 200px;
        @include mobile {
          width: 300px;
        }
      }

      img {
        max-height: 35px;
      }
    }

    &Language {
      align-items: center;
      display: flex;

      &-Controlium {
        display: flex;
        margin-right: 10px;
        @include mobile {
          margin-right: 15px;
        }

        svg {
          height: 20px;
          width: auto;
          @include mobile {
            height: 25px;
          }
        }
      }

      &-Select {
        button {
          display: flex;
        }

        svg {
          height: 20px;
          width: 20px;
          @include mobile {
            height: 24px;
            width: 24px;
          }
        }
      }
    }

    @include mobile {
      height: 70px;
    }
    @include fullhd {
      height: 90px;
      padding: 0 45px;
    }
  }

  &-Content {
    margin: 0 auto;
    max-width: 1920px;
    padding: 0;

    @include tablet {
      padding: 0 20px;
    }

    @include fullhd {
      padding: 0 40px;
    }
  }

  p {
    color: $gray-04;
    font-size: rem(12px);
    margin: 20px 0;
    padding: 0;
    text-align: center;
  }

  &-Background {
    align-items: center;
    background-image: url("#{$assetPath4}/png/bg-login.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    min-height: calc(100vh - 100px);
    width: 100%;

    @include mobile {
      min-height: calc(100vh - 120px);
    }

    @include tablet {
      border-radius: 12px;
      min-height: calc(100vh - 160px);
    }

    @include fullhd {
      min-height: calc(100vh - 200px);
    }
  }

  &-Footer {
    .Footer-Content {
      padding: 0 20px;

      @include fullhd {
        padding: 0 40px;
      }
    }
  }
}
