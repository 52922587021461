.SessionsControl {
  &-Header {
    align-items: center;
    display: flex;
    font-size: rem(14px);
    font-weight: $font-weight-bold;
    justify-content: space-between;
    min-height: 32px;
  }

  &-Hint {
    color: $gray-04;
    font-size: rem(12px);
    margin-bottom: 15px;
  }

  &-ActiveSession {
    label{
      display: block;
      font-size: 12px;
      margin: 10px 0 5px;
    }

    .Tabs {
      &-Tab {
        font-size: 14px;
        padding: 10px;
      }
    }

  }

  &-SelectUser {
    max-width: 330px;
  }

  &-UserSessions {
    .session-list {
      .session-item {
        @include flex-container(row, space-between);
        border-bottom: 1px solid $gray-02;
        margin: 0 0 5px;
        padding: 10px 0;

        &:last-child {
          border-bottom: 0;
        }

        &.current {
          border: 1px solid $gray-03;
          border-radius: $border-radius;
        }

        .left {
          .name {
            font-size: rem(14px);
            font-weight: bold;
            margin-bottom: 3px;

            .version {
              font-weight: normal;
            }
          }

          .info {
            @include flex-container(row, flex-start);
            color: $gray-03;
            font-size: rem(12px);

            .device-icon {
              margin: 0 10px 0 3px;
              text-align: center;
              width: 20px;
            }

            .device {
              display: block;
            }

            .location {
              display: block;
            }
          }
        }

        .right {
          @include flex-container(column, space-between);

          align-self: stretch;
          color: $gray-04;
          text-align: right;

          .last-activity {
            font-size: rem(12px);
          }

          .status-current {
            color: $green;
            padding: 0;
          }

        }
      }
    }
  }

  &-Pagination {
    @include flex-container(row, space-between);

    .text {
      color: $gray-04;
      font-size: rem(16px);
    }

    .pagination {
      margin: 0;
    }
  }

  &-EmptyList {
    color: $gray-04;
    font-size: rem(16px);
  }
}
