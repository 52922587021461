.Field {
  margin: 0 0 20px;
  position: relative;

  .Label {
    color: $black;
    display: block;
    font-size: rem(12px);
    margin: 0 0 5px;
  }

  [disabled] {
    background: #F2F2F2;
    color: $gray-04;
  }

  &-Radio {
    label {
      @include flex-container(row, flex-start, center, flex-start);

      .Label {
        margin: 0;
      }
    }
  }

  &-Textarea {
    &_hasError {
      textarea {
        border-color: $red;
      }
    }

    &_isEditor {
      &:focus-within {
        .Field-Textarea-RichButton,
        .DraftEditor-root {
          box-shadow: 0 5px 15px var(#{$prefix}blue-light);
        }
      }
    }

    .bold {
      font-weight: bold;
    }

    .italic {
      font-style: italic;
    }

    .underline {
      text-decoration: underline;
    }

    .DraftEditor-root,
    textarea {
      background-color: $white;
      border: 1px solid transparent;
      border-radius: 0 0 $border-radius $border-radius;
      box-shadow: $box-shadow;
      box-sizing: border-box;
      font-size: rem(14px);
      max-height: 180px;
      max-width: 100%;
      min-height: 100px;
      min-width: 200px;
      overflow: auto;
      padding: 12px 16px;
      resize: vertical;
      width: 100%;
      z-index: 0;

      &:focus {
        box-shadow: 0 5px 15px var(#{$prefix}blue-light);
      }
    }

    .public-DraftEditor-content {
      overflow-wrap: anywhere;
    }

    .public-DraftEditorPlaceholder-root {
      max-width: calc(100% - 17px);
    }

    &-RichButton {
      border-radius: $border-radius $border-radius 0 0;
      box-shadow: $box-shadow;

      padding: 10px 5px;

      button {
        font-size: rem(14px);
        line-height: 1;
        margin: 0;
        padding: 0;
      }

      @include flex-container(row, space-around);
    }
  }

  &-Avatar {
    align-items: center;
    display: flex;
    position: relative;

    &Image {
      svg,
      img {
        border-radius: $border-radius-circle;
        display: block;
        height: 100px;
        margin: 0 auto;
        width: 100px;
        @include tablet {
          height: 215px;
          margin: 0 auto;
          width: 215px;
        }
      }
    }

    &UploadField {
      color: var(#{$prefix}blue-dark);
      font-size: rem(18px);
      font-weight: $font-weight-bold;
      margin: 20px 10px;
      position: relative;
      text-align: center;

      input {
        bottom: 0;
        left: 0;
        opacity: 0;
        outline: none;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
      }

      @include tablet {
        margin: 20px 0 30px;
      }
    }

    @include tablet {
      display: block;
    }
  }

  &-FakeSwitch {
    align-items: center;
    background-color: transparent;
    border: 1px solid $green;
    border-radius: 100px;
    display: flex;
    height: 16px;
    justify-content: space-between;
    position: relative;
    width: 28px;

    &::after {
      background-color: $green;
      border-radius: 45px;
      content: "";
      height: 10px;
      left: auto;
      position: absolute;
      right: 3px;
      top: 2px;
      transition: .2s;
      width: 10px;
    }

    &-Gray {
      border: 1px solid $gray-03;

      &::after {
        background-color: $gray-03;
        left: 3px;
        right: auto;
      }
    }

    &-Green {
      background-color: $green;
      border: 1px solid $green;

      &::after {
        background-color: $white;
      }
    }
  }

  &-Switch {
    &_Disabled {
      opacity: 0.5;
    }

    label {
      margin: 0;
    }

    &-Input {
      display: none;

      &:checked {
        + .Field-Switch-Label {
          background-color: $green;
          border-color: $green;

          .Field-Switch-Button {
            background-color: $white;
            left: 50%;
          }
        }
      }

      &:active {
        .Field-Switch-Button {
          width: 30px;
        }
      }
    }

    &-Label {
      align-items: center;
      background-color: transparent;
      border: 1px solid $gray-03;
      border-radius: 100px;
      cursor: pointer;
      display: flex;
      height: 16px;
      padding: 0 3px;
      position: relative;
      transition: background-color 0.2s;
      width: 28px;
    }

    &-Button {
      background-color: $gray-03;
      border-radius: 45px;
      height: 10px;
      left: 0;
      position: relative;
      transition: 0.2s;
      width: 10px;
    }
  }

  &-ParentModal {
    position: relative;

    &-Input {
      align-items: center;
      background-color: hsl(0, 0%, 100%);
      border-radius: 4px;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.16);
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      flex-wrap: nowrap;
      font-size: 14px;
      height: 40px;
      justify-content: flex-start;
      min-height: 40px;
      outline: 0 !important;
      padding: 0 0 0 16px;
      position: relative;
      transition: all 100ms;

      &_disabledField {
        background-color: $gray-01;
        color: $gray-04;
        pointer-events: none;

        svg {
          min-width: 20px;

          path {
            fill: $gray-03;
          }
        }
      }

      span {
        @include truncate(calc(100% - 40px));
      }

      svg {
        margin-right: 10px;
        min-width: 20px;
      }
    }
  }

  &-Checkbox {
    .Label {
      cursor: default;
      font-size: rem(14px);
      margin-left: 10px;

      .help {
        color: $gray-04;
        display: block;
        font-size: rem(12px);
        margin-top: 5px
      }
    }

    @include flex-container(row, space-between, flex-start, center);
  }

  &-File {
    @include middle-inline-block();
    cursor: pointer;
    overflow: hidden;

    position: relative;
    width: auto;

    &UploadField {
      position: relative;

      input {
        bottom: 0;
        cursor: pointer;
        left: -140px;
        opacity: 0;
        outline: none;
        position: absolute;
        right: 0;
        top: 0;
        width: calc(100% + 140px);
        z-index: $zlayer-4;
      }
    }

    &UploadButton {
      color: var(#{$prefix}blue-dark);
      cursor: pointer;
      font-size: rem(14px);
      font-weight: $font-weight-bold;
      word-break: break-word;
      z-index: $zlayer-3;
    }
  }

  &-Input {
    &_hasError {
      input {
        border: 1px solid $red !important;
      }

      .Field-InputWrapperButton {
        //display: none;

        svg {
          fill: var(#{$prefix}blue-dark);
        }
      }

      .Field-InputWrapper-Password{
        border: 1px solid $red !important;

        input{
          border: 0 !important;
        }
      }
    }

    input {
      background-color: $white;
      border: 1px solid transparent;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      box-sizing: border-box;
      font-kerning: none;
      font-size: rem(14px);
      height: 40px;
      min-width: 200px;
      outline: none;
      padding: 12px;
      width: 100%;

      &:focus {
        box-shadow: 0 5px 15px var(#{$prefix}blue-light);
      }

      @extend %transition;
    }

    &Wrapper {
      position: relative;

      &Button {
        display: flex;
        outline: none;
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);

        svg {
          fill: var(#{$prefix}blue-dark);
        }
      }

      &-Password{
        background-color: $white;
        border: 1px solid var(--ps-blue-dark);
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        box-sizing: border-box;
        height: 40px;
        // min-width: 200px;
        width: 100%;

        &:focus {
          box-shadow: 0 5px 15px var(#{$prefix}blue-light);
        }
        @extend %transition;

        input {
          border: 0 !important;
          box-shadow: none;
          box-shadow: inset 20px 20px 0 20px $white;
          // padding: 0;
          height: 100%;
          width: calc(100% - 35px);

          &:focus {
            box-shadow: inset 20px 20px 0 20px $white;
          }
        }
      }
    }

    .help {
      color: var(#{$prefix}blue-dark);
      float: right;
      font-size: rem(12px);

      svg {
        fill: var(#{$prefix}blue-dark);
      }
    }
  }

  &-DatePicker {
    &_hasError {
      input {
        border: 1px solid $red !important;
      }
    }

    &Wrapper {
      position: relative;

      input {
        @extend %transition;
        background-color: $white;
        border: 1px solid transparent;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        box-sizing: border-box;
        font-kerning: none;
        font-size: rem(14px);
        height: 40px;
        min-width: 200px;
        outline: none;
        padding: 12px;
        width: 100%;

        &:focus {
          box-shadow: 0 5px 15px var(#{$prefix}blue-light);
        }
      }

      svg {
        fill: var(#{$prefix}blue-dark);
        pointer-events: none;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

}

.Required {
  input, textarea {
    border: 1px solid var(#{$prefix}blue-dark);
  }
}
