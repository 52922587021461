.InfoBoxKeyHistory {
  margin-left: -10px;
  margin-right: -10px;
  @include fullhd {
    margin-left: 0;
    margin-right: 0;
  }

  &-Box {
    max-width: calc(100% - 50px);
  }

  &-Header {
    font-size: rem(12px);
    margin: 0 0 10px;
  }

  &-List {
    font-size: rem(12px);
    margin: 0 -10px 0 0;
    overflow-y: auto;
    width: 100%;
  }

  &-Item {
    @include flex-container(row, space-between, flex-start, stretch);

    background-color: $gray-01;
    border-radius: 6px;
    border-radius: 6px;
    height: auto;
    margin-bottom: 10px;
    margin-left: 20px;
    padding: 10px 0;
    padding-left: 25px;
    position: relative;
    width: auto;

    &::after {
      bottom: -50%;
      content: "";
      height: 50%;
      left: -19px;
      position: absolute;
      top: auto;
      z-index: 0;
      @extend %tree-level;
    }

    &:last-child {
      &::after {
        content: none;
      }
    }
  }

  &-Actions {
    @include flex-container();

    padding: 0 10px;

    svg {
      @extend %transition;
    }

    button {
      display: flex;
    }

    .btn-revert {
      &:hover {
        svg {
          fill: var(#{$prefix}blue-dark);
        }
      }
    }
  }

  &-Info {
    .action {
      font-size: rem(12px);
      margin-bottom: 0;

      span {
        color: $black;
        font-weight: $font-weight-bold;
      }

      p {
        margin-bottom: 5px;

        &::first-letter {
          text-transform: uppercase;
        }
      }

      a {
        color: var(#{$prefix}blue-dark);
        font-weight: bold;
        margin-right: 5px;
      }

      @include tablet {
        font-size: rem(14px);
      }
    }

    span {
      color: $gray-04;
    }
  }

  &-Icon {
    left: -18px;
    margin-right: 10px;
    position: absolute;
    width: 15px;
    z-index: 0;
  }

  &-Circle {
    align-items: center;
    background-color: transparent;
    border-radius: 90%;
    display: flex;
    height: 35px;
    justify-content: center;
    width: 35px;

    svg {
      fill: $white;
    }
  }

  .Circle {
    &-Red {
      background-color: $red;
    }

    &-Green {
      background-color: $green;
    }

    &-Yellow {
      background-color: $yellow;
    }

    &-Blue {
      background-color: var(#{$prefix}blue-dark);
    }
  }

  &-Edit {
    width: 100%;
  }

  &-Field {
    @include flex-container(row, flex-start);

    color: $gray-04;
    margin: 0 0 5px;

    &:last-child {
      margin: 0;
    }
  }

  &-Label {
    margin-right: 10px;
    min-width: fit-content;
  }

  &-Value {
    @include truncate(100%);

    &-Description {
      p {
        display: inline;
        margin: 0;
      }
    }
  }
}
