.Table {
  overflow: hidden;
  width: 100%;

  &-Head {
    align-items: center;
    background-color: $white;
    border-bottom: 1px solid $gray-02;
    display: flex;
    font-size: 12px;
    height: 30px;
    justify-content: flex-start;
    padding: 0 8px;
    position: sticky;
    top: 94px;
    width: 100%;
    z-index: 2;

    &-Th {
      cursor: pointer;
      font-weight: $font-weight-normal;
      padding: 5px 10px;
      white-space: nowrap;

      &_active {
        font-weight: $font-weight-bold;

        .Table-ThTitle {
          margin-right: 5px;
        }
      }
    }

    &-SortIcon {
      &.ASC {
        transform: scaleY(-1);
      }

      @extend %transition;
    }

    @include desktop {
      padding: 0;
    }
    @include widescreen {
      top: 54px;
    }
  }

  &-Body {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &-Th {
    align-items: center;
    display: flex;
    height: 100%;
    padding: 5px 10px;
  }

  &-Row {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 42px;
    padding: 0 8px;
    position: relative;
    width: 100%;

    &:hover {
      background-color: $gray-01;
    }

    &_chosen {
      background: var(#{$prefix}blue-01);

      &:hover {
        background: var(#{$prefix}blue-01);
      }
    }

    @extend %transition;

    @include desktop {
      padding: 0;
    }
  }

  &-Empty {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding-top: 20px;
    width: 100%;

    svg {
      height: 50px;
      margin-bottom: 10px;
      width: 50px;
    }

    p {
      font-size: 12px;
      margin-bottom: 10px;
      max-width: 320px;
      text-align: center;
    }
  }

  &-EmptyStatus {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    padding-top: 20px;
    width: 100%;

    svg {
      height: 50px;
      margin-bottom: 10px;
      width: 50px;
    }

    p {
      font-size: 12px;
      margin-bottom: 10px;
      max-width: 320px;
      text-align: center;
    }
  }

  &-Badge {
    background-color: var(#{$prefix}blue-dark);
    border-radius: $border-radius-sm;
    color: $white;
    font-size: rem(12px);
    font-weight: $font-weight-bold;
    line-height: 14px;
    min-width: 20px;
    padding: 2px 3px;
    text-align: center;
    @include middle-inline-block();
  }
}

.Table-Head {
  &-Small {
    top: 54px;
    @include widescreen {
      top: 54px;
    }
  }

  &-Clean {
    height: 50px;
    padding-top: 20px;
    top: 0;
    @include widescreen {
      height: 50px;
      padding-top: 20px;
      top: 0;
    }
  }
}

// .Table {
//   margin-right: 0;
//   min-height: 300px;
//   overflow: hidden;
//   width: 100%;

//   &-Filter {
//     align-items: center;
//     background-color: $white;
//     border-bottom: 1px solid $gray-230;
//     display: flex;
//     flex-direction: column;
//     height: auto;
//     padding: 10px;
//     position: sticky;
//     top: 74px;
//     width: 100%;
//     z-index: 2;
//     @include mobile {
//       flex-direction: row;
//       height: 40px;
//       padding: 0 10px;
//       top: 80px;
//     }

//     &-Header{
//       margin: 0 0 10px;
//       max-width: 90px;
//       width: 100%;
//       @include mobile {
//         margin: 0;
//       }
//     }

//     &-InputGroup{
//       display: flex;
//       justify-content: flex-end;
//       width: 100%;
//     }

//     &-Select{
//       margin: 0 5px;
//       max-width: 250px;
//       width: 100%;
//       @include mobile {
//         margin: 0 0 0 20px;
//       }

//       &__control{
//         border: 1px solid $gray-04 !important;
//         height: 30px !important;
//         min-height: 30px !important;
//       }

//       &__dropdown-indicator{
//         padding: 0 10px !important;
//       }

//       &__placeholder{
//         font-size: 12px !important;
//         @include mobile {
//           font-size: 14px !important;
//         }
//       }
//     }
//   }

//   &-GrayColor {
//     color: $gray-04;
//   }

//   &-withIcon {
//     svg {
//       margin-right: 5px;
//       min-width: 40px;
//     }

//     @include flex-container(row, flex-start);
//   }

//   &-SearchHighLight {
//     background-color: $yellow;
//     color: $black;
//   }

//   &-Dot {
//     background: $gray-01;
//     border-radius: $border-radius-circle;
//     display: inline-block;
//     height: 15px;
//     width: 15px;

//     &_enabled {
//       background-color: $green;
//     }
//   }

//   &-TextCenter {
//     text-align: center;
//   }

//   @include widescreen {
//     margin-right: 20px;
//   }
// }
