$screen-mobile: 460px;
$screen-tablet: 768px;
$screen-height: 800px;
$screen-desktop: 1024px;
$screen-widescreen: 1216px;
$screen-keyblock: 1440px;
$screen-fullhd: 1680px;

@mixin mobile {
  @media (min-width: $screen-mobile) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $screen-tablet) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $screen-desktop) {
    @content;
  }
}

@mixin keyblock {
  @media (min-width: $screen-keyblock) {
    @content;
  }
}

@mixin widescreen {
  @media (min-width: $screen-widescreen) {
    @content;
  }
}

@mixin fullhd {
  @media (min-width: $screen-fullhd) {
    @content;
  }
}

@mixin height {
  @media (max-height: $screen-height) {
    @content;
  }
}

@mixin rwd($screen) {
  @media (min-width: $screen+"px") {
    @content;
  }
}
