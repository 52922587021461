.Groups {
  margin: 0 0 40px;

  &-Sync {
    height: auto;
    line-height: 16px;
    min-width: auto;
    padding: 8px 18px;
    text-align: left;
    white-space: normal;
    @include tablet {
      height: auto;
      padding: 13px 23px;
    }

    svg {
      min-width: 16px;
    }
  }

  &-MobileMenu {
    display: block;
    margin-bottom: 4px;
    @include widescreen {
      display: none;
      margin-bottom: 0;
    }

    .Groups-ButtonAdd {
      background-color: var(#{$prefix}blue-dark);
      border: 1px solid transparent;
      border-radius: 6px;
      color: $white;
      font-weight: bold;
      height: 36px;
      line-height: 1;
      margin-bottom: 10px;
      margin-right: 10px;
      min-height: auto;
      min-width: auto;
      padding: 8px 18px;
      white-space: nowrap;
      @include tablet {
        height: 40px;
        margin-bottom: 10px;
        min-width: 125px;
        padding: 13px 23px;
      }
    }
  }

  .Mobile-In {
    display: block;
    @include tablet {
      display: none;
    }
  }

  .Mobile-Out {
    display: none;
    @include tablet {
      display: block;
    }
  }

  &-Action {
    @include flex-container(row, flex-start);
    margin-top: 20px;
  }

  &-AccessList {
    font-size: rem(14px);
    margin-top: 20px;

    li {
      @include flex-container(row, flex-start);

      margin-bottom: 10px;

      svg {
        margin-right: 10px;
      }
    }
  }
}
