.Profile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 18px;

  @include mobile {
    flex-direction: row;
    justify-content: space-between;
  }

  &-Item {
    padding-bottom: 20px;
    width: 100%;
    @include mobile {
      width: 48%;
    }
  }

  &-Avatar {
    margin: 0 0 10px;
    text-align: center;

    &-Image {
      border-radius: 50%;
      height: 165px;
      width: 165px;
    }
  }

  &-Upload {
    border: 1px solid $gray-04;
    border-radius: $border-radius;
    cursor: pointer;
    font-size: rem(16px);
    font-weight: $font-weight-bold;
    height: 42px;
    line-height: 42px;
    margin: 10px auto;
    max-width: 260px;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: auto;

    &:hover {
      border-color: adjust-color($gray-04, $lightness: 10%);

      svg {
        fill: adjust-color($gray-04, $lightness: 10%);
      }

      .text {
        color: adjust-color($gray-04, $lightness: 10%);
      }
    }

    svg {
      margin: 0 10px 0 0;
      @extend %transition;
    }

    .text {
      color: $gray-04;
      font-size: rem(14px);
      font-weight: $font-weight-bold;
      @extend %transition;
    }

    input {
      bottom: 0;
      cursor: pointer;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    @include flex-container(row);
    @extend %transition;
  }

  &-Help {
    color: $gray-04;
    font-size: rem(12px);
    text-align: center;
  }

  .error {
    color: $red;
    font-size: 12px;
    margin: 10px 0;
    padding: 0 16px;
    text-align: center;
  }

  &-Field{
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    width: 100%;

    &-Label{
      font-size: 12px;
    }

    &-Value{
      font-size: 14px;
      font-weight: $font-weight-bold;
      @include truncate(300px);
    }
  }

  &-ResetPassword {
    margin: 0 0 20px;
    max-width: 100%;
    width: 100%;
    @include mobile {
      max-width: 300px;
    }
  }

  .LanguageSelectField{
    margin: 5px 0 15px;
    margin-top: 5px;
    max-width: 100%;
    @include mobile {
      max-width: 300px;
    }
  }

  &-Logout {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 100%;
    @include mobile {
      max-width: 300px;
    }
  }

}
