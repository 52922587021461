.InfoBoxUserSettings {
  .InfoBoxUserActions {
    justify-content: flex-start;
    margin: 0 -18px -18px;
  }

  .Field-FileUploadField{
    svg {
      fill: var(#{$prefix}blue-dark);
    }
  }

  &-UserAvatar {
    border: 1px solid var(#{$prefix}blue-dark);
    border-radius: 50%;
    height: 114px;
    object-fit: cover;
    object-position: center;
    width: 114px;
  }

  &-Avatar {
    @include flex-container(row, flex-start, flex-start, flex-end);

    .Field-File {
      @include flex-container();
      background: $white;
      border: 1px solid $gray-04;

      border-radius: 50%;
      bottom: 11px;
      height: 30px;
      left: 90px;
      margin: 0;

      position: absolute;
      width: 30px;
    }

    svg {
      fill: var(#{$prefix}blue-dark);
    }

    .image {
      height: 114px;

      margin-right: 10px;
      position: relative;
      width: 114px;
    }

    p {
      color: $gray-04;
      font-size: 12px;
    }

    &Label {
      display: block;
      margin-bottom: 10px;
    }
  }

  &-FirstName {
    @include flex-container(row, flex-start, flex-start, flex-start);

    .Field-Select {
      margin: 20px 10px 0 0;
      min-width: 90px;
      width: 90px;
    }

    &-Select{
      &__indicator{
        padding: 8px 0 !important;
      }
    }

    .Field-Input {
      flex: 1 0 auto;

      &Wrapper {
        input {
          min-width: 0;
          width: 100%;
        }
      }
    }
  }

  &-Submit {
    bottom: 18px;
    left: 18px;
    position: absolute;
  }

  &-Error {
    color: $red;
  }

  .Messages {
    margin: 5px 0 0;
  }

  &-AvatarLabel {
    font-size: 12px;
  }

  &-Blocked {
    @include flex-container(row, space-between, center center);

    background: #FEDDDE;
    margin: -15px -18px 10px;
    padding: 10px 10px 10px 25px;

    p {
      @include flex-container();
      margin: 0;
      padding: 0;

      svg {
        margin-right: 5px;
      }
    }
  }
}
