/**
 * Variables
 * Variables should follow the `$component-state-property-size` formula for
 * consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
 */
$assetPath2: "./../../node_modules/ss-img";
$assetPath3: "./../../../node_modules/ss-img";
$assetPath4: "./../../../../node_modules/ss-img";

$prefix: "--ps-";
$fonts-dir: "../fonts";

// Color system
//$fonts-dir: "/static/fonts" !default;

$white: #fff !default;

$black: #000 !default;
$yellow: #f0a600 !default;

$green: #49b109 !default;
$red: #e3000b !default;

$blue-dark: #1a90bb !default;
$blue-light: #80cff1 !default;
$blue-01: #eafbfd !default;

$gray-01: #fafafa !default;
$gray-02: #e5e5e5 !default;
$gray-03: #a7a7a8 !default;
$gray-04: #727272 !default;

$box-shadow-color: rgba(0, 0, 0, 0.16) !default;
$box-shadow: 0 5px 15px $box-shadow-color !default;
$transition: all ease 0.5s;

$zlayer-0: 1000 !default;
$zlayer-1: 1010 !default;
$zlayer-2: 1020 !default;
$zlayer-3: 1030 !default;
$zlayer-4: 1040 !default;
$zlayer-5: 1050 !default;
$zlayer-6: 1060 !default;
$zlayer-7: 1070 !default;
$zlayer-8: 1080 !default;
$zlayer-9: 1090 !default;
$zlayer-10: 1100 !default;

// Typography
// Font, line-height, and color for body text, headings, and more.

$font-family-lato: "Lato", sans-serif !default;
$font-family-base: $font-family-lato !default;

$font-size-base: 14px !default; // Assumes the browser default, typically `16px`

$font-weight-lighter: 100 !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: 900 !default;

$font-weight-base: $font-weight-normal !default;

$line-height-base: 1.225 !default;
$line-height-lg: 1.5 !default;
$line-height-sm: 1.3 !default;

$border-width: 2px !default;
$border-color: $white !default;

$border-radius: 6px !default;
$border-radius-lg: 9px !default;
$border-radius-sm: 3px !default;
$border-radius-slg: 24px !default;
$border-radius-circle: 50% !default;

$gap: 20px !default;
