$main-size: $font-size-base;
$body-family: $font-family-base;
$body-weight: $font-weight-base;
$body-line-height: $line-height-base;
$body-bg: $white;
$body-size: $rem-baseline;
$body-color: $black;
$header-family: $font-family-base;

html {
  font-size: $main-size;
  overflow-x: hidden;
  overflow-y: auto;
  text-size-adjust: 100%;
}

article,
aside,
figure,
footer,
header,
hgroup,
section {
  display: block;
}

h1,
h2,
header {
  font-family: $header-family;
}

svg {
  g{
    clip-path: unset;
  }
}

h1,
h2 {
  margin-bottom: 0.5rem;
}

input,
select,
textarea,
button {
  @include form-item;
  appearance: none;
}

body {
  background-color: $body-bg;
  color: $body-color;
  font-family: $body-family;
  font-size: $main-size;
  font-style: normal;
  font-weight: $body-weight;
  line-height: $body-line-height;
  position: relative;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: top;
}

label,
p {
  margin-bottom: $gap;
}

a {
  color: var(#{$prefix}blue-dark);
  outline: none;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    outline: none;
    text-decoration: none;
  }

  &.btn {
    display: inline-block;
    height: 40px;
    line-height: 40px;

    &-blue {
      background-color: var(#{$prefix}blue-dark);
      border-radius: $border-radius;
      color: $white;
      justify-content: center;
      min-width: 125px;
      text-align: center;
    }
  }

  strong {
    color: currentColor;
  }
}

button {
  background-color: transparent;
  border: 0 solid transparent;
  cursor: pointer;
  outline: none;

  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

[role="button"] {
  cursor: pointer;
  outline: none;

  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
}

.react-datepicker{
  font-family: $font-family-lato;
}

.otp-key {
  margin: 0 0 10px;
  text-align: center;
  width: 100%;
}

.ManageKey-Option {
  p{
    @include truncate(100%);
    margin: 0 !important;
  }

  svg{
    min-width: 20px;
  }
}