.Filter {
  align-items: center;
  animation: modalOpen 0.2s ease-in-out;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 0;
  display: flex;
  height: auto;
  justify-content: center;
  left: 0;
  margin-bottom: 0;
  min-height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  padding: 40px 10px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
  @extend %scrollbar;

  @include widescreen {
    background: $gray-01;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    display: block;
    height: auto;
    margin: 0;
    margin-bottom: 20px;
    max-height: calc(100% - 15px);
    min-height: auto;
    padding: 0;
    position: sticky;
    right: 20px;
    top: 20px;
    width: 100%;
  }
  @include fullhd {
    max-width: 100%;
  }

  &-Container {
    background-color: $white;
    border-radius: 6px;
    max-height: 90vh;
    max-width: 500px;
    overflow: auto;
    width: 100%;
    @include widescreen {
      border-radius: 0;
      max-height: auto;
      overflow: unset;
    }
  }

  &-Header {
    @include flex-container(row, flex-start);
    background-color: $gray-01;
    color: $black;
    font-size: rem(16px);
    font-weight: $font-weight-bold;
    height: 50px;
    padding: 15px 18px;
    position: sticky;
    top: 0;
    z-index: 1;

    svg {
      fill: var(#{$prefix}blue-dark);
      min-width: 20px;
    }

    &-Title {
      margin: 0 20px 0 10px;
      @include truncate(100%);
    }
  }

  &-Content {
    background: $white;
    padding: 15px 18px;
  }

  &-Field {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;

    label{
      align-items: center;
      display: block;
      display: flex;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 10px;
      width: 100%;

      svg {
        fill: var(#{$prefix}blue-dark);
        margin-right: 10px;
      }
    }

    input {
      border: 0;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      cursor: pointer;
      height: 40px;
      outline: none;
      padding: 10px;
      text-align: left;
      width: 100%;
    }

    &-Date {
      align-items: center;
      display: flex;

      input {
        font-size: 14px;
        text-align: center;
      }
    }

    &-Checkbox{
      display: flex;
      margin-bottom: 10px;
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }

      .Field-Checkbox{
        align-items: center;
        display: flex;
        justify-content: flex-start;
        margin: 0;
        width: 100%;

        .Checkbox{
          margin: 0;
          margin-right: 10px;
          width: auto;
        }

        .Label{
          margin: 0;
        }
      }
    }

    .css-mohuvp-dummyInput-DummyInput{
      height: 0;
      padding: 0;
    }
  }

  &-Select {
    margin: 0;

    &__input-container{
      margin: 0 !important;
      margin-top: -4px !important;
      max-height: 40px;
    }

    &__option{
      .ManageKey-Option {
        p{
          @include truncate(100%);
          margin: 0 !important;
        }

        svg{
          min-width: 20px;
        }
      }
    }

    &__value-container {
      align-items: center !important;
      display: flex !important;
      overflow: unset !important;
    }

    &__placeholder {
      position: absolute !important;
      z-index: 0;
    }

    &__input{
      box-shadow: none !important;
    }

    .ReactVirtualized__List {
      max-height: 200px;
    }
  }

  &-ActivityLogSelect {
    input {
      box-shadow: none;
      height: auto;
    }
  }

  &-SelectItem {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    &-Name {
      align-items: center;
      display: flex;
      max-width: calc(100% - 25px);

      svg{
        fill: var(#{$prefix}blue-dark);
        margin-right: 5px;
        min-width: 20px;
      }

      span{
        font-size: 12px;
        @include truncate(100%);
      }
    }

    button{
      display: flex;

      &:hover {
        svg{
          fill: $red;
          @extend %transition;
        }
      }
    }
  }

  &-ParentModal {
    position: relative;

    label{
      align-items: center;
      display: block;
      display: flex;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 10px;
      width: 100%;

      svg {
        fill: var(#{$prefix}blue-dark);
        margin-right: 10px;
      }
    }

    &-Input {
      align-items: center;
      background-color: hsl(0, 0%, 100%);
      border: 0;
      border-color: hsl(0, 0%, 80%);
      border-radius: 4px;
      border-style: solid;
      border-width: 0;
      box-shadow: 0 5px 15px rgba(0, 0, 0, .16);
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      flex-wrap: nowrap;
      font-size: 14px;
      height: 40px;
      justify-content: flex-start;
      min-height: 40px;
      outline: 0 !important;
      padding: 0 0 0 16px;
      position: relative;
      transition: all 100ms;

      &_disabledField {
        background-color: $gray-01;
        color: $gray-04;
        pointer-events: none;

        svg {
          min-width: 20px;

          path {
            fill: $gray-03;
          }
        }
      }

      span {
        @include truncate(calc(100% - 40px));
      }

      svg {
        margin-right: 10px;
        min-width: 20px;
      }
    }
  }

  &-Close {
    cursor: pointer;
    display: flex;
    padding: 0;
    position: absolute;
    right: 15px;
    top: calc(50% - 7px);

    &:hover {
      fill: $red;
    }
  }

  &-Action {
    @include flex-container(row, space-between);
    background-color: $gray-01;
    border-radius: 0 ;
    bottom: 0;
    flex-wrap: nowrap;
    flex-wrap: wrap;
    padding: 18px;
    position: sticky;

  }
}