%tree-level {
  background-image: repeating-linear-gradient($black 0%, $black 10%, $white 10%, $white 100%);
  background-position: left;
  background-repeat: repeat-y;
  background-size: 1px 5px;
  margin: 0 0 0 20px;

  margin: 0 0 0 18px;
  padding: 0 0 0 14px;
}

%tree-level-export {
  background-image: repeating-linear-gradient($black 0%, $black 10%, $white 10%, $white 100%);
  background-position: left;
  background-repeat: repeat-y;
  background-size: 1px 5px;
  margin: 0 0 0 20px;

  margin: 0 0 0 16px;
  padding: 0 0 0 14px;
}

%scrollbar {
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  -o-overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    background-color: rgba($gray-01, 0.3);
    border-radius: 4px;
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($gray-02, 0.5);
    border-radius: 4px;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
