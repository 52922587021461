.GroupFolderTree {
  &-Icon {
    align-items: center;
    display: flex;
    width: 100%;
  }

  &-Form {
    align-items: center;
    display: flex;
    width: 100%;

    &-Item {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      width: 100%;

      &-Remove {
        align-items: center;
        display: flex;
        min-width: 30px;

        &:hover {
          svg {
            fill: $red;
          }
        }

        svg {
          fill: $gray-03;
        }
      }

      &-Recursion {
        align-items: center;
        display: flex;
        justify-content: center;
        min-width: 30px;
        @include tablet {
          min-width: 80px;
        }
      }

      &-Access {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        width: 100%;

        &-Icon {
          background: transparent;
          border-radius: 90%;
          margin-right: 5px;
          min-height: 14px;
          min-width: 14px;

          &-write {
            background: $green;
          }

          &-no_access {
            background: $red;
          }

          &-read {
            background: $yellow;
          }

          &-Text {
            display: none;
            margin: 0;
            @include tablet {
              display: block;
            }
          }
        }

        &-Select {
          &__control {
            background: transparent !important;
            border: 0 !important;
            box-shadow: none !important;
            height: auto !important;
            min-height: 20px !important;
            padding-left: 0 !important;
            width: 50px !important;
            @include tablet {
              width: 150px !important;
            }
          }

          &__value-container {
            justify-content: flex-end !important;
          }

          &__indicator {
            padding: 0 !important;
          }
        }
      }
    }
  }

  &-Content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &-Folder {
    @include flex-container(row, space-between);
    background: $gray-01;
    filter: grayscale(100%);
    height: 50px;
    position: relative;
    width: 100%;

    &:hover {
      background: var(#{$prefix}blue-01);
    }

    &_isOpen {
      background: var(#{$prefix}blue-01);
      // margin: 0 0 10px 0;
    }

    &_isFolderTree {
      background: none;
      filter: none;

      &:hover {
        background: var(#{$prefix}blue-01);
      }
    }

    &_isActive,
    &_isActiveMulti {
      background: var(#{$prefix}blue-01);
      font-weight: bold;
    }
  }

  .EmptyFolderStatus {
    min-width: 36px;
  }

  .FolderStatus {
    margin: 0 10px;
  }

  .FolderIcon {
    margin: 0 10px 0 0;
    min-width: 30px;
  }

  &-Title {
    align-items: center;
    display: flex;
    flex: 1;

    span {
      max-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @include tablet {
        max-width: 200px;
      }
    }
  }

  &-Count {
    color: $gray-04;
    font-size: rem(10px);
    font-weight: $font-weight-normal;
  }

  @for $i from 1 through 10 {
    &_level_#{$i} {
      background-image: repeating-linear-gradient($black 0%, $black 10%, $white 10%, $white 100%);
      background-position: left;
      background-repeat: repeat-y;
      background-size: 1px 5px;
      margin: 0;
      padding: 0;
      @include tablet {
        background-image: repeating-linear-gradient($black 0%, $black 10%, $white 10%, $white 100%);
        background-position: left;
        background-repeat: repeat-y;
        background-size: 1px 5px;
        margin: 0 0 0 20px;

        margin: 0 0 0 18px;
        padding: 0 0 0 14px;
      }
    }
  }
}
