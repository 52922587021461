@keyframes slide-from-left {
  0% {
    opacity: 0;
    transform: translateX(-6px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes modalOpen {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInShow {
  0% {
    opacity: 0;
    //transform: scale(0)
  }

  100% {
    opacity: 1;
    //transform: scale(1)
  }
}
