.InfoBoxGroupFolders-Modal-List_showModalTree {
  &::after {
    background-color: rgba($black, .5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }
}

.InfoBoxGroupFolders {
  &-Table {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .Modal-Search {
      margin-bottom: 0;
      max-width: 70%;
      padding: 10px 0;
      @include tablet {
        max-width: 50%;
      }
    }

    span {
      margin-right: 5px;
      max-width: 80px;
      text-align: center;
      @include tablet {
        margin-right: 30px;
      }
    }
  }

  &-Select {
    // &__placeholder {
    //   padding-left: 25px;
    //   position: relative;
    //   top: 0;
    //   transform: none;

    //   &::after {
    //     background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="rgb(167,167,167)" width="20" height="20" viewBox="0 0 20 20"><g><g><path d="M2 7.853A5.859 5.859 0 0 1 7.853 2a5.86 5.86 0 0 1 5.853 5.853 5.86 5.86 0 0 1-5.853 5.853A5.859 5.859 0 0 1 2 7.853zM16.826 20l3.173-3.173-5.24-5.24a7.794 7.794 0 0 0 .947-3.734C15.706 3.523 12.183 0 7.853 0S0 3.523 0 7.853s3.523 7.853 7.853 7.853c1.35 0 2.622-.343 3.733-.946l3.158 3.158z"></path></g></g></svg>') center center no-repeat;
    //     content: "";
    //     height: 20px;
    //     left: -1px;
    //     position: absolute;
    //     width: 20px;
    //   }
    // }

    &__indicator {
      padding-right: 0 !important;
    }

    &__single-value {
      p {
        margin: 0;
      }
    }

    &__menu-list{
      filter: blur(0);

      .ManageKey-Option{
        p{
          @include truncate(100%);
          margin: 0 !important;
        }

        svg{
          min-width: 20px;
        }
      }
    }
  }

  &-Field {
    &-Recursion {
      margin-left: 15px;
    }
  }

  &-Error {
    color: $red;
    margin-bottom: 10px;
  }

  &-ButtonAdd {
    margin-left: 8px;
    margin-top: 10px;
  }

  &-Modal {
    box-sizing: border-box;
    height: auto;
    left: calc(50% - 10px);
    margin: 10px;
    max-height: 95vh;
    min-height: 70vh;
    overflow: auto;
    padding: 16px 0 20px;
    position: fixed;
    width: calc(100% - 20px);

    @include tablet {
      max-width: 800px;
      padding: 16px 0 30px;
    }

    &-Header {
      align-items: center;
      display: flex;
      margin-bottom: 10px;
      padding: 0 15px;
      width: 100%;
      @include tablet {
        padding: 0 20px;
      }

      svg {
        fill: var(#{$prefix}blue-dark);
        min-width: 30px;
      }

      p {
        font-size: 18px;
        font-weight: bold;
        margin: 0;
        margin-left: 15px;
        @include truncate(calc(100% - 60px));
      }
    }

    &-Action {
      align-items: center;
      background-color: $gray-01;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      padding: 20px;
      position: absolute;
      width: 100%;
      z-index: 1;
    }

    &-List {
      max-height: 70vh;
      min-height: calc(70vh - 135px);
      overflow: auto;
      padding: 0 15px 80px;
      width: 100%;
      @extend %scrollbar;
      @include tablet {
        padding: 0 20px 80px;
      }

      .Field {
        margin: 0;
      }

      &-Header {
        margin: 10px 0 15px;
      }

      &-Item {
        align-items: center;
        display: flex;
        padding-right: 30px;
        position: relative;
        width: 100%;
      }

      &-Checkbox {
        align-items: center;
        display: flex;
        margin-top: 8px;
        padding-left: 0;
        @include tablet {
          padding-left: 20px;
        }

        label {
          margin-right: 10px;
        }

        .Label {
          margin: 0;
          padding-top: 2px;
        }
      }

      &-Select {
        margin-right: 15px;
        max-width: 300px;
        width: 100%;

        svg {
          min-width: 20px;
        }
      }

      &-Remove {
        display: flex;
        position: absolute;
        right: 0;
        top: 11px;

        &:hover {
          svg {
            fill: $red;
          }
        }

        svg {
          fill: $gray-03;
          margin-right: 0;
          @extend %transition;
        }
      }

      &-Access {
        display: flex;
        padding-top: 13px;
        width: auto;

        .Field-Radio {
          margin-right: 12px;

          &:nth-child(1) {
            .Radio-Input:checked + span::after,
            .Radio-Input:not(:checked) + span::after {
              background-color: $green;
            }

            .Radio-Input:checked + span::before {
              border-color: $green;
            }
          }

          &:nth-child(2) {
            .Radio-Input:checked + span::after,
            .Radio-Input:not(:checked) + span::after {
              background-color: $yellow;
            }

            .Radio-Input:checked + span::before {
              border-color: $yellow;
            }
          }

          &:nth-child(3) {
            .Radio-Input:checked + span::after,
            .Radio-Input:not(:checked) + span::after {
              background-color: $red;
            }

            .Radio-Input:checked + span::before {
              border-color: $red;
            }
          }

          label {
            margin-bottom: 0;
          }

          .Label {
            padding-top: 1px;
          }
        }
      }
    }
  }

  &-List {
    @extend %scrollbar;
    max-height: 260px;
    min-height: 260px;
    overflow: auto;
    padding-top: 15px;

    &-Item {
      align-items: center;
      display: flex;
      padding: 0 10px 10px;
      width: 100%;

      svg {
        height: 22px;
        margin-right: 10px;
        min-width: 22px;
        width: 22px;
      }

      p {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 0;
        @include truncate(100%);
      }
    }
  }

  &-Search {
    .SearchInput {
      &-Wrap {
        width: 100%;
      }

      &-Icon {
        height: 16px;
        left: 10px;
        top: 50%;
        width: 16px;

        svg {
          height: 16px;
          width: 16px;
        }
      }

      &-Input {
        background-color: $white;
        height: 38px;
        padding-left: 40px;
        width: 100%;
      }

      &-Button {
        height: 16px;
        width: 16px;

        svg {
          height: 16px;
          width: 16px;
        }
      }
    }
  }

  .Field {
    margin: 0;
  }

  &-Submit {
    bottom: 20px;
    left: 20px;
    position: absolute;
  }

  &-Empty,
  &-Header {
    font-size: rem(14px);
    padding-left: 10px;
  }

  &-Empty,
  &-Header,
  &-List {
    margin: 0 0 10px;
  }

  &-FieldFolder {
    @include flex-container(row, flex-start);
  }

  &-FieldAccess {
    @include flex-container(row, space-between);
  }

  .Field-Checkbox {
    @include flex-container(row, flex-start, center);
    margin: 0 0 10px;

    .Checkbox {
      margin: 0 10px 0 0;
    }

    .Label {
      margin: 0;
    }
  }

  &-Button {
    &Add {
      font-size: rem(14px);
      font-weight: $font-weight-bold;
      padding: 0 !important;
    }
  }
}
