/* Typography */
h1 {
  font-size: rem(18px);
  line-height: 2.25rem;
  @include desktop {
    font-size: rem(20px);
    line-height: 2.75rem;
  }
}

h2 {
  font-size: rem(18px);
  line-height: 2.25rem;
}

header {
  font-size: rem(16px);
  line-height: 1.5rem;
}
