.FolderNavHeader {
  @include flex-container(row, flex-start);
  @extend %transition;
  background-color: $gray-02;
  border-radius: $border-radius $border-radius 0 0;
  height: 40px;
  padding: 0 15px;
  width: 100%;

  p {
    flex: 1;
    font-size: rem(14px);
    font-weight: $font-weight-bold;
    margin: 0;
  }

  .FolderIcon {
    margin: 0 14px 0 0;
  }

  &-Name {
    flex: 1;
    font-size: rem(18px);
    font-weight: $font-weight-bold;
  }

  &-Role {
    color: $gray-04;
    display: block;
    font-size: rem(12px);
    font-weight: $font-weight-normal;
  }

  .FolderStatus {
    margin: 0 10px;
  }

  &-Structure {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-right: 10px;
    padding-right: 0;
  }
}
