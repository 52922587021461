.TableUsersLocal {
  min-height: 40vh;

  .Table-Row_hidden {
    filter: grayscale(100%);
  }

  &-Checkbox {
    display: none;
    @include desktop {
      display: flex;
    }
  }

  &-Status {
    display: none;
    @include tablet {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      width: 80px;
    }

    &-Item {
      align-items: center;
      background-color: $white;
      border: 1px solid var(#{$prefix}blue-dark);
      border-radius: 6px;
      display: flex;
      line-height: 10px;
      padding: 3px;

      span {
        color: var(#{$prefix}blue-dark);
        font-size: 12px;
      }

      &-0 {
        border: 1px solid $gray-03;

        span {
          color: $gray-03;
        }
      }

      &-2 {
        border: 1px solid $yellow;

        span {
          color: $yellow;
        }
      }
    }
  }

  &-Icon {
    min-width: 36px;
    padding: 5px 0;

    svg {
      fill: var(#{$prefix}blue-dark);
    }
  }

  &-Otp {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 50px;
  }

  &-Title {
    align-items: center;
    display: flex;
    max-width: calc(100% - 136px);
    padding-left: 0;
    white-space: normal;
    width: 100%;

    &-Column {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      width: 100%;

      p {
        font-size: 14px;
        font-weight: $font-weight-bold;
        margin: 0;
        @include truncate(100%);
      }

      span {
        color: $gray-04;
        display: block;
        font-size: 12px;
        width: 100%;
        @include truncate(100%);
      }
    }

    @include tablet {
      max-width: calc(100% - 336px);
      width: 100%;
    }

    @include desktop {
      max-width: calc(100% - 372px);
      width: 100%;
    }
  }

  &-Role {
    color: $gray-04;
    display: none;
    font-size: 12px;
    width: 100px;
    @include tablet {
      display: flex;
    }
  }

  &-Groups {
    align-items: center;
    display: flex;
    width: 70px;

    &-Item {
      align-items: center;
      background-color: $white;
      border: 1px solid var(#{$prefix}blue-dark);
      border-radius: 6px;
      display: flex;
      margin: 0 3px;
      padding: 4px;

      &_isEmpty {
        border: 1px solid $gray-03;

        svg {
          fill: $gray-03 !important;
        }

        span {
          color: $gray-03 !important;
        }
      }

      svg {
        fill: var(#{$prefix}blue-dark);
        height: 14px;
        margin-right: 5px;
        width: 14px;
      }

      span {
        color: var(#{$prefix}blue-dark);
        font-size: 12px;
      }
    }
  }

  .Table-Head-Th {
    &:nth-child(1) {
      display: none;
      @include desktop {
        display: flex;
      }
    }

    &:nth-child(2) {
      max-width: calc(100% - 110px);
      padding-left: 36px;
      width: 100%;
      @include tablet {
        max-width: calc(100% - 300px);
        width: 100%;
      }
      @include desktop {
        max-width: calc(100% - 336px);
        padding-left: 36px;
      }
    }

    &:nth-child(3) {
      display: none;
      width: 80px;
      @include tablet {
        display: flex;
      }
    }

    &:nth-child(4) {
      display: none;
      width: 100px;
      @include tablet {
        display: flex;
      }
    }

    &:nth-child(5) {
      text-align: center;
      width: 50px;
    }

    &:nth-child(6) {
      width: 70px;
    }
  }
}
