.GettingQRCode {
  &-QRImage {
    text-align: center;
  }

  &-QRText {
    font-size: rem(16px);
    padding: 0 20px !important;
  }
}
