.ManageKey {
  @extend %scrollbar;

  box-sizing: border-box;
  height: auto;
  left: 50%;
  max-height: 90vh;
  // test
  // overflow: auto;
  // padding: 16px 16px 20px;
  position: fixed;
  width: calc(100% - 20px);

  .Field-Server{
    width: 60%;
  }

  .Field-Port{
    width: 60%;
  }

  &-Field{
    &-Title{
      align-items: center;
      display: flex;
      margin-bottom: 10px;

      &::after {
        border-bottom: 1px solid $gray-03;
        content: "";
        display: block;
        width: 100%;
      }

      p{
        font-weight: bold;
        margin: 0 10px 0 0;
        text-transform: uppercase;
        white-space: nowrap;
      }
    }
  }

  @include tablet {
    max-width: 780px;
    // test
    // padding: 16px 30px 30px;
  }

  &-HeaderKey {
    fill: var(#{$prefix}blue-dark);

    &-Sealing {
      fill: var(#{$prefix}blue-dark);
    }
  }

  .error {
    color: $red;
    margin-top: -25px;
  }

  .error-notMargin {
    color: $red;
    margin-top: 10px;
  }

  &-PassError {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;

    &_Filter {
      filter: grayscale(1);
    }

    &-Item {
      align-items: center;
      display: flex;

      &_success {
        span {
          color: $green;
        }
      }

      svg {
        margin-right: 5px;
      }

      span {
        color: $red;
        font-size: 12px;
        margin: 0;
        @include tablet {
          font-size: 14px;
        }
      }
    }

    .ManageKey-PassError-Item_success {
      span {
        color: $green;
      }
    }
  }

  &-Header {
    align-items: center;
    color: $black;
    display: flex;
    font-size: rem(18px);
    font-weight: $font-weight-bold;
    height: 50px;
    margin: 0;
    padding: 0 16px;
    width: 100%;
    @include tablet {
      max-width: 780px;
      padding: 0 30px;
    }

    span {
      display: block;
      @include truncate(70%);
      @include tablet {
        @include truncate(90%);
      }
    }

    svg {
      min-width: 30px;
    }

    &Key {
      margin-right: 10px;
    }

    &Favorite {
      margin: 0 10px;
      padding: 0;
    }

    &Link {
      margin: 0;
      padding: 0;
    }

    &-Logo {
      align-items: center;
      background-color: var(#{$prefix}blue-01);
      border-radius: 4px;
      display: flex;
      height: 30px;
      justify-content: center;
      margin-right: 10px;
      min-width: 30px;
      position: relative;

      &-Image {
        border-radius: 4px;
        height: 30px;
        width: 30px;
      }

      &-Text {
        color: var(#{$prefix}blue-dark);
        font-size: 10px;
        font-weight: bold;
        margin: 0;
        text-transform: uppercase;
      }
    }

    @include flex-container(row, flex-start);
  }

  &-Crop {
    align-items: center;
    background-color: rgba($color: $black, $alpha: 0.3);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 50%;
    padding: 10px;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 9;

    @include tablet {
      padding: 30px;
    }
  }

  &-Upload {
    border: 0;
    color: var(#{$prefix}blue-dark);
    cursor: pointer;
    font-size: rem(14px);
    height: 40px;
    justify-content: flex-start !important;
    line-height: 40px;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: auto;

    svg {
      fill: var(#{$prefix}blue-dark);
      margin: 0 10px 0 0;
      @extend %transition;
    }

    .text {
      color: var(#{$prefix}blue-dark);
      font-size: rem(14px);
      font-weight: $font-weight-bold;
      @extend %transition;
    }

    input {
      bottom: 0;
      cursor: pointer;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    @include flex-container(row);
    @extend %transition;
  }

  &-AttachedInput {
    display: flex;
    justify-content: space-between;
    line-height: 16px;
    margin-bottom: 0;
    width: auto;
    width: calc(100% - 26px);

  }

  .PassInput {
    input {
      padding-right: 10px;
    }

    .input {
      &::before {
        content: none;
      }
    }

    .password {
      &::before {
        background-color: var(#{$prefix}blue-dark);
        content: "";
        height: 2px;
        position: absolute;
        right: 9px;
        top: 18px;
        transform: rotate(-45deg);
        width: 22px;
      }
    }
  }

  form {
    width: 100%;
  }

  &-AddFile {
    display: flex;
    flex-wrap: wrap;

    svg {
      fill: var(#{$prefix}blue-dark);
      margin-right: 10px;
      min-width: 14px;
    }
  }

  &-FolderSecurityLevel {
    background-color: rgba($yellow, .4);
    font-size: rem(14px);
    margin: 0 0 16px;
    padding: 10px 16px;

    .FolderIcon {
      margin: 0 15px 0 0;
    }

    @include tablet {
      padding: 10px 30px;
    }

    span {
      display: inline-block;
      font-weight: bold;
      @include truncate(100%);
      @include tablet {
        @include truncate(100%);
      }
    }

    b {
      margin-right: 5px;
    }

    &-Text {
      min-width: 245px;
    }

    @include flex-container(row, flex-start);
  }

  &-Actions {
    background-color: $gray-01;
    border-radius: 0 0 9px 9px;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    left: 0;
    padding: 15px;
    width: 100%;

    .Button {
      margin: 0 20px 0 0;
    }
  }

  &-Content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-height: calc(90vh - 183px);
    overflow: auto;
    padding: 0 16px;
    @include tablet {
      padding: 0 30px;
    }

    &Left {
      width: 100%;
      @include tablet {
        margin-right: 30px;
        width: 55%;
      }
    }

    &Right {
      width: 100%;
      @include tablet {
        flex: 1;
      }

      .DraftEditor-root {
        max-width: 100%;

        .public-DraftEditor-content {
          overflow-wrap: anywhere !important;
        }
      }
    }

    @include tablet {
      @include flex-container(row, flex-start, flex-start, flex-start);
    }
  }

  &-PasswordGenerator {
    position: relative;

    .Length {
      min-width: 85px;
    }

    .Level {
      flex: 1;
      margin-left: 10px;
      margin-right: 10px;
    }

    @include flex-container(row, flex-start);
  }

  &-Option {
    @include flex-container(row, flex-start);

    svg {
      fill: var(#{$prefix}blue-dark);
      margin-right: 5px;
    }
  }

  &-SealingBox {
    align-items: center;
    display: flex;
    flex: 1;
    width: 100%;

    .Field-Select {
      width: 100%;
    }
  }

  &-Sealing {
    display: flex;
    flex-direction: column;
    margin: 0 0 20px;
    margin-left: 3%;
    min-width: 45%;

    &-Label {
      align-items: center;
      display: flex;
      margin: 0 0 5px;

      label {
        margin: 0;
      }

      svg {
        fill: var(#{$prefix}blue-dark);
        margin-left: 5px;
      }
    }

    &-Field {
      align-items: center;
      display: flex;
      height: 40px;

      p {
        margin: 0 0 0 10px;
      }
    }

    &-Icon {
      margin-right: 10px;

      path {
        fill: $gray-03;
      }

      &-Blue {
        fill: var(#{$prefix}blue-dark);
        margin-right: 10px;
      }
    }
  }

  .Label {
    color: $black;
    display: block;
    font-size: rem(14px);
    margin: 0 0 5px;
  }

  &-Attached {
    margin-bottom: 10px;

    &Label {
      color: $black;
      display: block;
      font-size: rem(14px);
      margin: 0 0 5px;
    }

    &File {
      @include flex-container(row, space-between);
    }

    &FileInfo {
      svg {
        fill: var(#{$prefix}blue-dark);
        margin-right: 0;
        min-width: 16px;
      }

      span {
        color: var(#{$prefix}blue-dark);
        font-size: rem(14px);
        font-weight: bold;
        margin-left: 10px;
        @include truncate(230px);
      }

      @include flex-container(row, flex-start);
    }

    &FileRemove {
      border: 0;
      min-width: 0;
      padding: 4px;
      width: auto;

      &:hover {
        border: 0;
      }

      .Button-Icon {
        fill: $gray-03;
        height: 16px;
        margin: 0;
        width: 16px;
      }
    }
  }

  &-PasswordValidation {
    background-color: $red;
    border-radius: $border-radius-sm;
    height: 3px;
    margin-bottom: 5px;
    width: 100%;

    &_Filter {
      filter: grayscale(1);
    }

    &_success {
      background-color: $green;
    }
  }

  &-PasswordStrongLevel {
    color: $red;
    font-size: rem(12px);
    line-height: 20px;
    margin-bottom: 10px;

    &_level_2 {
      color: $yellow;
    }

    &_level_3 {
      color: $green;
    }

    &_level_4 {
      color: $green;
    }
  }

  &-Indicator {
    background: $gray-02;
    border-radius: $border-radius-sm;
    height: 3px;

    &Level {
      background: $red;
      border-radius: $border-radius-sm;
      height: 3px;

      width: 2%;

      &_level_1 {
        width: 25%;
      }

      &_level_2 {
        background: $yellow;
        width: 50%;
      }

      &_level_3 {
        background: $green;
        width: 75%;
      }

      &_level_4 {
        background: $green;
        width: 100%;
      }

      @extend %transition;
    }
  }

  &-Logo {
    margin-bottom: 20px;
    position: relative;

    .Remove-Logo {
      bottom: 10px;
      height: auto;
      left: 160px;
      line-height: normal;
      position: absolute;

      &:hover {
        svg {
          fill: $red;
        }

        .text {
          color: $red;
        }
      }

      svg {
        fill: var(#{$prefix}blue-dark);
      }
    }

    &-Image {
      border-radius: 16px;
      cursor: pointer;
      display: inline-block;
      font-weight: $font-weight-bold;
      height: 150px;
      margin: 10px 0;
      position: relative;
      width: 150px;

      &:hover {
        .ManageKey-Logo-Image-Mask {
          opacity: 1;
          transition: all .4s ease-in-out;
        }
      }

      &-Name {
        margin: 5px 0;
        text-align: center;
        @include truncate(100%);
      }

      img {
        border: 1px solid var(#{$prefix}blue-dark);
        border-radius: 16px;
        height: 100%;
        object-fit: cover;
        width: 100%;
      }

      &-Mask {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        opacity: 0;
        position: absolute;
        width: 100%;

        svg {
          fill: var(#{$prefix}blue-dark);
        }
      }
    }

    .Field {
      margin-bottom: 0;

      .label {
        display: block;
        margin-bottom: 5px;
      }
    }
  }

  &-CheckInput {
    position: relative;

    &_checkedPasswordSuccess,
    &_checkedLoginSuccess {
      input {
        border: 1px solid $green;
      }
    }

    &_checkedPasswordError,
    &_checkedLoginError {
      input {
        border: 1px solid $yellow;
      }
    }

    &-Loading {
      color: var(#{$prefix}blue-dark);
      font-size: 1rem;
      position: absolute;
      right: 10px;
      top: 0;

      &::after {
        animation: loadingInput 1s ease alternate infinite;
        color: var(#{$prefix}blue-dark);
        content: ".";
      }
    }

    &-Checked {
      align-items: center;
      color: $green;
      display: flex;
      font-size: 1rem;
      position: absolute;
      right: 0;
      top: 0;

      svg {
        margin-right: 5px;
      }
    }

    &-Warning {
      align-items: center;
      color: $yellow;
      display: flex;
      font-size: 1rem;
      position: absolute;
      right: 0;
      top: 0;

      svg {
        margin-right: 5px;
      }
    }

    &-Waiting {
      align-items: center;
      color: $blue-dark;
      display: flex;
      font-size: 1rem;
      position: absolute;
      right: 0;
      top: 0;

      svg {
        margin-right: 5px;
      }
    }
  }

  .FieldCardNumber {
    flex: 1;
    min-width: 200px;
    position: relative;

    svg {
      position: absolute;
      right: 10px;
      top: 37px;
    }
  }

  &-Row {
    @include flex-container(flex-start, flex-start, flex-start, flex-start);
    width: 100%;

    & > div {
      &:not(:first-child) {
        margin-left: 20px;
      }

      input {
        min-width: initial;
      }

      &.FieldProtocol {
        flex: 1;
      }

      &.FieldExpirationDate {
        flex: 1;
      }

      &.FieldEmail {
        flex: 1;
      }
    }

    &-Mail {
      .Field {
        &:first-child {
          width: 60%;
        }

        &:last-child {
          width: 40%;
        }
      }
    }

    &-MailType {
      .Field-Select {
        width: 110px;
      }
    }
  }

  .Messages {
    margin-bottom: 0;
  }
}

@keyframes loadingInput {
  60% {
    text-shadow: 0.35em 0 0 currentColor;
  }

  100% {
    text-shadow: 0.35em 0 0 currentColor, 0.75em 0 0 currentColor;
  }
}
