.Modal {
  &-FolderGroups {
    max-width: 800px;
    width: calc(100% - 20px);
  }
}

.RenderGroup{
  min-height: 50vh;
  width: 100%;

  .Field{
    margin: 0;
  }

  &-Member {
    align-items: center;
    background-color: $white;
    border: 1px solid var(#{$prefix}blue-dark);
    border-radius: 6px;
    display: flex;
    padding: 2px;
    width: fit-content;

    svg {
      height: 14px;
      width: 14px;
    }
  }

  &-Header {
    align-items: center;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;

    &-Info {
      display: none;
      font-size: 12px;
      margin-right: 80px;
      max-width: 80px;
      text-align: center;
      @include tablet {
        display: block;
      }
    }
  }

  &-List {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    width: 100%;
  }

  &-FieldGroup{
    align-items: center;
    display: flex;
    margin-bottom: 5px;
    padding: 5px 0;
    padding-right: 30px;
    position: relative;
    width: 100%;

    &_noAccess{
      filter: grayscale(100%);
    }

    &_hide {
      display: none;
    }
  }

  &-Name {
    align-items: center;
    display: flex;
    width: calc(100% - 140px);

    &_noAccess{
      filter: grayscale(100%);
    }

    p{
      margin: 0;
      @include truncate(100%);
    }

    svg{
      fill: var(#{$prefix}blue-dark);
      margin-right: 10px;
      min-width: 25px;
    }
    @include tablet {
      width: 60%;
    }
  }

  &-Row {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-left: 20px;
    width: auto;
    @include tablet {
      padding: 0;
      width: 40%;
    }

    &-Member {
      display: none;
      min-width: 50px;
      @include tablet {
        display: block;
      }

      span {
        color: var(#{$prefix}blue-dark);
      }
    }
  }

  &-Remove{
    display: flex;
    position: absolute;
    right: 0;
    top: calc(50% - 9px);

    &:hover {
      svg {
        fill: $red;
      }
    }
  }

  &-Search {
    max-width: 300px;
    position: relative;
    width: 100%;

    &-Icon {
      left: 10px;
      position: absolute;
      top: calc(50% - 8px);
      width: auto;
    }

    label {
      display: inline-block;
      margin: 0 0 5px;
    }

    input {
      background-color: $gray-01;
      border: 1px solid transparent;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      display: block;
      height: 36px;
      outline: none;
      padding: 0 16px;
      padding-left: 36px;
      width: 100%;
      @extend %transition;

      &:focus {
        background-color: $white;
        @extend %transition;
      }
    }
  }

  &-Select {
    &__control {
      background: transparent !important;
      border: 0 !important;
      box-shadow: none !important;
      height: auto !important;
      min-height: 20px !important;
      padding-left: 0 !important;
      width: 50px !important;
      @include tablet {
        width: 150px !important;
      }
    }

    &__value-container {
      justify-content: flex-end !important;
    }

    &__indicator {
      padding: 0 !important;
    }

    &-Name {
      width: 100%;
      @include tablet {
        width: 60%;
      }

      &__menu {
        &-list {
          max-height: 150px !important;
        }
      }
    }
  }

  &-Option{
    align-items: center;
    display: flex;

    svg{
      margin-right: 10px;
      min-width: 20px;
    }

    span{
      display: block;
      @include truncate(100%);
    }

    &-Icon{
      background: transparent;
      border-radius: 90%;
      margin-right: 5px;
      min-height: 14px;
      min-width: 14px;

      &_value_write {
        background: $green;
      }

      &_value_no_access {
        background: $red;
      }

      &_value_read {
        background: $yellow;
      }
    }

    &-Hide {
      display: none !important;
      @include tablet {
        display: block !important;
      }
    }
  }

  &-Recursion {
    align-items: center;
    display: flex;
    justify-content: center;
    min-width: 40px;
    @include tablet {
      min-width: 80px;
    }
  }
}