.TableGroups {
  min-height: 40vh;

  &-Checkbox {
    display: none;
    @include desktop {
      display: flex;
    }
  }

  &-Icon {
    padding: 5px 0;

    svg {
      fill: var(#{$prefix}blue-dark);
    }
  }

  &-Name {
    align-items: center;
    display: flex;
    max-width: calc(100% - 150px);
    width: 100%;
    @include tablet {
      max-width: calc(100% - 235px);
    }
    @include desktop {
      max-width: calc(100% - 325px);
    }

    p {
      font-weight: bold;
      margin: 0;
      @include truncate(100%);
    }
  }

  &-Folders {
    align-items: center;
    display: none;
    @include tablet {
      display: flex;
      max-width: 145px;
      width: 100%;
    }
    @include desktop {
      max-width: 200px;
    }

    &-Item {
      align-items: center;
      background-color: $white;
      border: 1px solid $gray-03;
      border-radius: 6px;
      display: flex;
      margin: 0 3px;
      padding: 4px;

      &_write {
        border: 1px solid $green;
      }

      &_read {
        border: 1px solid $yellow;
      }

      &_no_access {
        border: 1px solid $red;
      }

      &_users, &_folders {
        border: 1px solid var(#{$prefix}blue-dark);

        span {
          color: var(#{$prefix}blue-dark) !important;
        }
      }

      svg {
        height: 14px;
        margin-right: 5px;
        width: 14px;
      }

      span {
        color: $gray-04;
        font-size: 12px;
      }
    }
  }

  &-FoldersCount {
    display: flex;

    svg {
      fill: var(#{$prefix}blue-dark);
    }
    @include tablet {
      display: none;
    }
  }

  &-Users {
    max-width: 60px;
    width: 100%;

    svg {
      fill: var(#{$prefix}blue-dark);
    }
  }

  .Table-Head-Th {
    &:nth-child(1) {
      display: none;
    }

    &:nth-child(2) {
      padding-left: 40px;
      width: 100%;
      @include desktop {
        padding-left: 75px;
      }
    }

    &:nth-child(3) {
      width: 50px;
      @include tablet {
        display: block;
        max-width: 125px;
        width: 100%;
      }
      @include desktop {
        display: block;
        max-width: 180px;
        width: 100%;
      }
    }

    &:nth-child(4) {
      width: 80px;
    }
  }
}
