.Customize {
  &-Delimiter {
    background-color: $gray-02;
    height: 1px;
    margin: 3px 18px;
    width: calc(100% - 36px);
  }

  &-Section {
    @extend %transition;
    border-radius: 6px;
    padding: 10px 18px;

    &:hover {
      background-color: $gray-01;
    }
  }

  &-FixInputs {
    .Field {
      margin: 0 !important;
      min-height: 82px;
    }

    .Messages-Error {
      margin: 0;
    }
  }

  &-SubmitMail {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: 18px;
  }

  &-Header {
    @include flex-container(row, space-between);
    margin: 0 0 10px;

    &-Name {
      color: $black;
      font-size: rem(14px);
      font-weight: $font-weight-bold;
    }
  }

  &-Content {
    @include flex-container(row, flex-start, flex-start, flex-start);
    flex-wrap: wrap;

    &-Left {
      flex: 1;
      margin-right: 0;
      min-width: 280px;
      @include tablet {
        margin-right: 10px;
        min-width: 300px;
      }
    }

    &-Right {
      flex: 1;
      min-width: 280px;
      @include tablet {
        min-width: 300px;
      }

      &-Row {
        align-items: center;
        display: flex;
        justify-content: space-between;

        .Field {
          &-Input{
            input {
              max-width: 150px;
              min-width: auto;
            }
          }

          &-Select {
            margin-left: 10px !important;
            width: 100%;
          }
        }
      }
    }

    .Messages-Error{
      margin-bottom: 0;
    }

    .error {
      color: $red;
      font-size: 12px;
      margin-top: 10px;
    }

    &-Help {
      color: $gray-04;
      font-size: rem(12px);
      margin: 0 0 10px;

      b {
        color: $black;
      }
    }

    &-Submit {
      margin-top: 10px
    }

    &-Logo {
      border: 1px dashed $gray-02;
      border-radius: $border-radius;
      box-sizing: border-box;
      margin-top: 15px;
      overflow: hidden;
      padding: 10px;
      width: 100%;

      @include tablet {
        margin-top: 0;
      }

      img {
        border-radius: $border-radius;
        width: 100%;
      }
    }

    &-Bg {
      background-image: url("#{$assetPath4}/png/bg-login.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      border: 1px solid $gray-02;
      border-radius: $border-radius;
      display: block;
      height: 0;
      margin-top: 15px;
      padding-bottom: 50%;
      padding-top: 20px;
      position: relative;
      width: 100%;
      @include tablet {
        margin-top: 0;
      }
    }

    .Field {
      margin: 0 0 10px;
    }
  }

  &-ColorPicker {
    align-items: center;
    display: flex;
    padding: 0 0 10px;

    button{
      margin-left: 20px;
      margin-top: 22px;
    }

    &-Input {
      label {
        font-size: rem(12px);
      }
    }

    &-Color {
      @include flex-container(row, flex-start);
      align-items: center;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      font-weight: $font-weight-bold;
      height: 42px;
      margin-top: 5px;
      padding: 0 15px;
      position: relative;
      width: 140px;
      z-index: 9;

      ::-webkit-color-swatch-wrapper {
        padding: 0;
      }

      ::-webkit-color-swatch {
        border: 0;
        border-radius: 50%;
      }

      label {
        cursor: pointer;
        font-size: rem(14px);
        margin: 0;
        padding: 0;
      }

      input {
        background-color: $white;
        border: 0;
        cursor: pointer;
        height: 16px;
        margin-right: 10px;
        padding: 0;
        width: 16px;
      }
    }
  }

  &-ColorsPreview {
    border: 1px dashed $gray-02;
    border-radius: 6px;
    margin-top: 20px;
    overflow: hidden;

    @include tablet {
      margin-top: 42px;
    }

    .color {
      height: 50px;
      width: 100%;
    }

    .primary {
      @include flex-container(row, space-evenly, stretch, center);

      .color-1 {
        background: var(#{$prefix}blue-dark);
      }

      .color-2 {
        background: var(#{$prefix}blue-light);
      }

      .color-3 {
        background: var(#{$prefix}blue-01);
      }
    }

    .secondary {
      @include flex-container(row, space-evenly, stretch, center);

      .color-1 {
        background: var(#{$prefix}gray-04);
      }

      .color-2 {
        background: var(#{$prefix}gray-03);
      }

      .color-3 {
        background: var(#{$prefix}gray-02);
      }

      .color-4 {
        background: var(#{$prefix}gray-01);
      }
    }
  }

  input {
    &[type="file"] {
      display: none;
    }
  }
}
