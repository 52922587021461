.OTPAdmin {
  .switcher {
    @include flex-container(flex-start, flex-start);
    display: block;
    margin: 0;

    .Switch {
      margin: 0 10px 0 0;
    }
  }

  &-QRCodeModal {
    padding: 20px;

    .Modal-Content {
      @include flex-container(column, flex-start, flex-start, flex-start);

      max-width: 300px;
      min-width: 200px;

      header {
        font-size: rem(18px);
        font-weight: $font-weight-bold;
        margin-bottom: 15px;
      }

      p {
        font-size: rem(16px);
      }

      img {
        margin: 0 auto;
      }

      .otp-key {
        margin: 0 0 10px;
        text-align: center;
        width: 100%;
      }

      label {
        display: block;
        font-size: rem(16px);
        margin: 0 0 5px;
      }
    }
  }
}
