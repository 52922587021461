.KeysList {
  min-height: calc(100% - 90px);
  position: relative;

  &-Checkbox {
    display: none;
    @include desktop {
      display: flex;
      min-width: 36px;
    }
  }

  .Table {
    &-Row {
      &_chosen {
        background: $gray-01;
      }
    }
  }

  .SearchSharing {
    fill: var(#{$prefix}blue-dark);
  }

  &-Sharing {
    fill: #A7A7A8;

    &_isSharing {
      fill: var(#{$prefix}blue-dark);
    }
  }

  &-Sealing {
    fill: var(#{$prefix}blue-dark);
  }

  &-Type {
    display: flex;
    justify-content: center;
    max-width: 30px;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    width: 100%;

    svg {
      fill: var(#{$prefix}blue-dark);
    }
  }

  .Logo-Mask {
    align-items: center;
    background-color: var(#{$prefix}blue-01);
    border-radius: 4px;
    display: flex;
    height: 30px;
    justify-content: center;
    width: 30px;
  }

  &-Logo {
    height: 30px;
    position: relative;
    width: 30px;

    &-Image {
      border-radius: 4px;
      height: 30px;
      width: 30px;
    }

    &-Text {
      color: var(#{$prefix}blue-dark);
      font-size: 10px;
      font-weight: bold;
      margin: 0;
      text-transform: uppercase;
    }

    &-Icon {
      align-items: center;
      background: $white;
      border-top-left-radius: 10px;
      bottom: 0;
      display: flex;
      height: 14px;
      justify-content: center;
      position: absolute;
      right: 0;
      width: 14px;

      svg {
        height: auto;
        width: 9px;
      }
    }
  }

  &-Title {
    align-items: center;
    display: flex;
    max-width: calc(100% - 60px);
    white-space: normal;
    width: 100%;

    &-Column {
      display: flex;
      flex-direction: column;
      max-width: calc(100% - 50px);
      overflow: hidden;
      width: 100%;

      p {
        font-size: 14px;
        font-weight: $font-weight-bold;
        margin: 0;
        @include truncate(100%);
      }

      span {
        color: $gray-04;
        display: block;
        font-size: 12px;
        width: 100%;
        @include truncate(100%);

        a {
          color: $gray-04;
        }
      }
    }

    &-Icon {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: flex-end;
      min-width: 90px;

      svg {
        margin: 0 4px;
      }
    }

    @include tablet {
      width: calc(100% - 210px);
    }

    @include desktop {
      width: calc(100% - 246px);
    }
  }

  &-Date {
    color: $gray-04;
    display: none;
    font-size: 12px;

    @include tablet {
      display: flex;
      width: 150px;
    }
  }

  &-Action {
    align-items: center;
    flex-direction: row-reverse;
    flex-grow: 1;
    padding: 0;

    &:hover &-Row {
      background-color: $gray-01;
      margin-right: 10px;
      visibility: visible;
      width: 100%;
    }

    &-Button {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 10px;
      width: 30px;

      span {
        background-color: $gray-04;
        border-radius: 90%;
        height: 3px;
        margin: 1px;
        width: 3px;
        @extend %transition;

        &_chosen {
          background-color: $black;
        }
      }

      svg {
        fill: var(#{$prefix}blue-dark);
      }
    }

    &-Favorite {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      padding: 0;
      width: auto;

      svg {
        fill: var(#{$prefix}blue-dark);
      }
    }

    &-Item {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 6px;
    }

    &-Row {
      align-items: center;
      background-color: $white;
      display: flex;
      height: 100%;
      justify-content: flex-end;
      transition: background-color 0.5s;
      visibility: hidden;
      width: 0;

      &_chosen {
        background-color: $gray-01;
        left: auto;
        right: 30px;
      }

      svg {
        fill: var(#{$prefix}blue-dark);
      }
    }
  }

  .Table-Head-Th {
    &:nth-child(1) {
      display: none;
      @include desktop {
        display: flex;
        min-width: 36px;
      }
    }

    &:nth-child(2) {
      opacity: 0;
      @include desktop {
        display: flex;
        min-width: 40px;
        // opacity: 1;
        padding-left: 5px;
      }
    }

    &:nth-child(3) {
      padding-left: 0;
      width: 100%;
    }

    &:nth-child(4) {
      display: none;
      width: 115px;
      @include tablet {
        display: flex;
      }
    }

    &:nth-child(5) {
      width: 70px;
    }
  }

  @include tablet {
    .Table-Row {
      &:hover {
        .KeysList {
          &-Date {
            display: none;
          }

          &-Action {
            &-Button {
              span {
                background-color: $black;
              }
            }

            &-Row {
              background-color: $gray-01;
              margin-right: 10px;
              visibility: visible;
              width: 100%;
            }
          }
        }
      }

      &_chosen {
        &:hover {
          .KeysList-Action {
            &-Row {
              background-color: $gray-01;
            }
          }
        }
      }
    }
  }
}
