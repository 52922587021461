.BMobileMenu {
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 40px;
  justify-content: center;
  left: 0;
  outline: none;
  padding: 0;
  position: absolute;
  top: 10px;
  width: 40px;
  z-index: 9;

  &_isOpen {
    left: calc(100% - 40px);
    position: fixed;

    span {
      &:nth-child(1) {
        margin-top: 0;
        transform: rotate(-45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        margin-top: -15px;
        transform: rotate(45deg);
      }

      @extend %transition;
    }
  }

  span {
    background-color: var(#{$prefix}blue-dark);
    display: block;
    height: 3px;
    margin: 3px auto;
    width: 24px;
    @extend %transition;
  }

  @extend %transition;

  @include desktop {
    display: none;
  }
}
