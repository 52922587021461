.ContentWrapper {
  display: flex;
  height: fit-content;
  max-width: 100%;
  min-height: 100%;
  width: 100%;
  @include desktop {
    max-width: calc(100% - 320px);
  }
  @include fullhd {
    max-width: calc(100% - 400px);
  }
}

.Content {
  max-width: 100%;
  padding-bottom: 10px;
  width: 100%;
  @include desktop {
    padding: 0 0 14px;
  }
  @include widescreen {
    max-width: calc(100% - 360px);
  }
}

.ContentHeader{
  align-items: center;
  background-color: $white;
  display: flex;
  height: 54px;
  justify-content: flex-end;
  padding: 0 10px;
  position: sticky;
  top: 0;
  z-index: 2;

  & > *:not(:last-child) {
    margin-right: 10px;
  }

  @include desktop {
    padding: 0 10px;
  }

  &-Clean{
    display: block;
    width: 100%;
    @include tablet {
      display: none;
    }
  }

  &-CleanFull{
    display: block;
    width: 100%;
  }

  &-Margin {
    margin-right: 10px;
  }
}

.ContentHeaderMobile{
  align-items: center;
  background-color: $white;
  display: flex;
  height: 40px;
  justify-content: space-between;
  padding: 0 10px;
  position: sticky;
  top: 54px;
  z-index: 2;
  @include desktop {
    padding: 0 10px;
  }
  @include widescreen {
    display: none;
  }

  &-Button{
    align-items: center;
    display: flex;

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }

  &-Info{
    align-items: center;
    display: flex;
    max-width: 100%;
    overflow: hidden;
    width: 100%;
  }

  &-Folder{
    align-items: center;
    display: flex;
    max-width: 100%;

    h2{
      font-size: 14px;
      line-height: 30px;
      margin: 0 0 0 10px;
      @include truncate(100%);
    }
  }
}
