.Tabs {
  @include flex-container(row, flex-start);

  border-bottom: 1px solid $gray-03;
  margin: 0 0 20px;

  &-Tab {
    color: $gray-04;
    font-size: rem(16px);
    font-weight: $font-weight-bold;
    padding: 15px;
    position: relative;
    transition: $transition;

    &_isActive {
      color: $black;
      font-weight: $font-weight-bold;

      &::after {
        @include pseudo();

        background: var(#{$prefix}blue-dark);
        border-radius: 2px;
        bottom: -2px;
        height: 3px;
        left: 0;
        width: 100%;
      }
    }
  }

  &-Search {
    border-bottom: 1px solid $gray-03;
    width: 100%;
    @include flex-container(row, flex-start);

    .Tabs {
      &-Tab {
        align-items: center;
        display: flex;
        font-size: rem(14px);
        font-weight: $font-weight-bold;
        padding: 8px 16px;

        &_isActive {
          svg {
            fill: var(#{$prefix}blue-dark);
          }
        }

        sup {
          font-size: 9px;
        }

        svg {
          margin-right: 10px;
          min-width: 20px;
        }
      }
    }
  }
}
