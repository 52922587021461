$size: 16px;
$width: $size - 4;
$height: 2px;

.FolderStatus {
  @include middle-inline-block();
  @extend %transition;
  background-color: var(#{$prefix}blue-dark);
  border-radius: $border-radius-sm;
  height: $size;
  min-width: $size;

  position: relative;
  width: $size;

  &::after {
    @extend %transition;
    @include pseudo();
    background: $white;
    height: $height;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotateZ(90deg);

    width: $width;
  }

  &::before {
    @include pseudo();
    background: $white;
    height: $height;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);

    width: $width;
  }

  &_isActive {
    background-color: $gray-03;

    &::after {
      @include pseudo();
      background: $white;
      height: $height;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);

      width: $width;
    }
  }
}
