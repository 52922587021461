.ContentPagination {
  align-items: center;
  background-color: $white;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 0 10px;
  position: sticky;
  width: 100%;
  z-index: 2;

  .pagination {
    margin: 10px auto;
    width: 100%;
  }

  p {
    width: 100%;
  }

  // &-Select{
  //   width: 90px;
  // }

  // .ContentPagination-Select-Prefix {
  //   &__control {
  //     border-radius: 6px;
  //     box-shadow: $box-shadow;
  //     height: 30px;
  //     min-height: auto;

  //     &:hover {
  //       border-color: var(#{$prefix}blue-dark);
  //     }
  //   }

  //   &__menu {
  //     max-height: 100px;
  //     overflow: hidden;
  //   }

  //   &__value-container,
  //   &__indicators {
  //     margin-top: -4px;
  //   }

  //   &__indicator {
  //     padding: 8px 6px 8px 0;
  //     @include mobile {
  //       padding: 8px;
  //     }
  //   }

  //   &__option {
  //     padding: 4px 12px;
  //   }

  //   &__menu-list {
  //     max-height: 100px;
  //   }
  // }

  .pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 11px auto;
    outline: none;

    li {
      margin: 0 2px;
      @include mobile {
        margin: 0 5px;
      }

      a {
        align-items: center;
        border: 1px solid var(#{$prefix}blue-dark);
        border-radius: 6px;
        color: var(#{$prefix}blue-dark);
        display: flex;
        font-size: 12px;
        justify-content: center;
        padding: 4px 6px;
        @include mobile {
          font-size: 14px;
          padding: 4px 8px;
        }
      }
    }

    .active {
      a {
        background-color: var(#{$prefix}blue-dark);
        color: $white;
      }
    }

    .break-me {
      margin: 0;

      a {
        border: 1px solid transparent;
      }
    }

    .pagination-prev,
    .pagination-next {
      cursor: pointer;
      margin: 0;

      a {
        border: 1px solid transparent;
      }

      svg {
        fill: var(#{$prefix}blue-dark);
        height: 15px;
        width: 10px;
        @include mobile {
          height: 18px;
          width: 12px;
        }
      }
    }

    .disabled {
      svg {
        fill: $gray-03;
      }

      a {
        cursor: default;
      }
    }
  }
}
