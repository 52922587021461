.FolderFilter {
  align-items: flex-start;
  animation: modalOpen 0.2s ease-in-out;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  margin-bottom: 0;
  min-height: 60vh;
  overflow: auto;
  padding: 40px 10px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
  @extend %scrollbar;

  @include widescreen {
    background: $gray-01;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    display: block;
    height: auto;
    margin-bottom: 20px;
    min-height: auto;
    min-width: 300px;
    padding: 0;
    position: sticky;
    right: 20px;
    top: 20px;
    width: 100%;
  }
  @include fullhd {
    max-width: 100%;
    min-width: 380px;
  }

  &-box {
    background: $gray-01;
    border-radius: $border-radius;
    max-height: 95vh;
    max-width: 500px;
    min-width: 100%;
    overflow: auto;
    position: relative;
    @extend %scrollbar;
    @include tablet {
      height: auto;
      max-height: 100%;
      min-width: 500px;
    }
    @include widescreen {
      background: none;
      border-radius: 0;
      min-width: 100%;
    }
  }

  header {
    @include flex-container(row, space-between);

    font-size: rem(18px);
    font-weight: $font-weight-bold;
    margin: 20px 20px 0;

    .content {
      svg {
        margin: 0 11px 0 0;
      }
    }

    button {
      margin: 0;
      padding: 0;
    }
  }

  &-Content {
    @include flex-container(column, flex-start, flex-start, stretch);

    margin: 0 20px 20px;

    label {
      display: block;
      font-size: rem(14px);
      font-weight: $font-weight-bold;
      margin: 20px 0 15px;
    }
  }

  &-Checkbox {
    @include flex-container(row, flex-start, flex-start, center);

    .Label {
      margin: 0;
    }

    label {
      margin: 0 10px 0 0;
    }
  }

  &-SelectItem {
    @include flex-container(row, space-between);

    margin: 10px 0;

    &:last-child {
      margin: 10px 0 0;
    }

    .name {
      svg {
        @include middle-inline-block();
        margin-right: 5px;
      }

      span {
        @include middle-inline-block();
        font-size: rem(12px);
        font-weight: $font-weight-bold;
      }
    }
  }

  &-Actions {
    @include flex-container(row, space-between);
    background: $gray-01;
    flex-wrap: wrap;
    margin: 10px -20px -20px;
    padding: 20px;
    @include tablet {
      flex-wrap: nowrap;
    }
    @include widescreen {
      flex-wrap: wrap;
    }

  }
}
