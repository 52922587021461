@charset "utf-8";

@import "~react-toastify/dist/ReactToastify.css";
@import "~ss-ui/dist/styles.css";
@import "~draft-js/dist/Draft.css";
@import "~react-datepicker/dist/react-datepicker.min.css";

@import "vars";
@import "mixins/all";
@import "mixins/spacing";
@import "animation";
@import "extension";
@import "fonts";

@import "base/minireset";
@import "base/generic";
@import "base/typography";
@import "base/helpers";
@import "base/media";

@import "../components/App/App";
@import "../components/Form/Form";
@import "../components/Messages/Messages";
@import "../components/Table/Table";
@import "../components/Layout/LayoutComponent/Layout";
@import "../components/Layout/LayoutAnonymous/LayoutAnonymous";
@import "../components/Layout/Footer/Footer";
@import "../components/Layout/Header/Header";
@import "../components/Layout/Aside/Aside";
@import "../components/Layout/Aside/AsideContent";
@import "../components/Layout/Content/Content";
@import "../components/Layout/Filter/Filter";

@import "../components/Generic/FolderStatus/FolderStatus";
@import "../components/Generic/ImageCropper/ImageCropper";
@import "../components/Generic/ImageWatcher/FileWatcher";
@import "../components/Generic/LoadingContainer/LoadingContainer";
@import "../components/Generic/Modal/Modal";
@import "../components/Generic/ModalFolderSelect/ModalFolderSelect";
@import "../components/Generic/ModalFolderSelect/fragments/Tree/Tree";
@import "../components/Generic/ModalFolderSelect/fragments/TreeList/TreeList";
@import "../components/Generic/Tabs/Tabs";
@import "../components/Generic/Pagination/Pagination";
@import "../components/Generic/ExpireWindow/ExpireWindow";
@import "../components/Generic/Unsubscribe/Unsubscribe";
@import "../components/Generic/BMobileMenu/BMobileMenu";
@import "../components/Generic/Breadcrumbs/Breadcrumbs";
@import "../components/Generic/ErrorBoundary/ErrorBoundary";

@import "../components/InfoBox/Group/InfoBoxGroup";
@import "../components/InfoBox/Group/Multiply/Multiply";
@import "../components/InfoBox/Group/Single/Folders/Folders";
@import "../components/InfoBox/Group/Single/Members/Members";
@import "../components/InfoBox/Group/Single/Settings/Settings";
@import "../components/InfoBox/Group/Single/Folders/Tree";

@import "../components/InfoBox/ActivityLog/Header/Header";
@import "../components/InfoBox/ActivityLog/InfoBoxActivityLog";

@import "../components/InfoBox/Key/InfoBoxKey";
@import "../components/InfoBox/Key/Multiply/Multiply";
@import "../components/InfoBox/Key/Single/History/History";
@import "../components/InfoBox/Key/Single/Settings/Settings";
@import "../components/InfoBox/Key/Single/Sharing/Sharing";

@import "../components/InfoBox/InfoBox/InfoBox";

@import "../components/InfoBox/Folder/InfoBoxFolder";
@import "../components/InfoBox/Folder/Single/Groups/RenderGroup";

@import "../components/InfoBox/FolderFilter/FolderFilter";

@import "../components/InfoBox/User/InfoBoxUser";
@import "../components/InfoBox/User/Multiply/Multiply";
@import "../components/InfoBox/User/Single/Groups/Groups";
@import "../components/InfoBox/User/Single/Security/Security";
@import "../components/InfoBox/User/Single/Settings/Settings";

@import "../components/Navigation/Folders/Folders";
@import "../components/Navigation/Folders/Single/Single";
@import "../components/Navigation/Folders/Tree/Tree";
@import "../components/Navigation/Header/Header";
@import "../components/Navigation/NavigationComponent/Navigation";
@import "../components/Navigation/FolderNavHeader/FolderNavHeader";

@import "../hoc/withLoading";

@import "../pages/FolderManagement/FolderManagement";
@import "../pages/FolderManagement/TableFolderManagement";
@import "../pages/ForgotPassword/ForgotPassword";
@import "../pages/InstallPassword/InstallPassword";
@import "../pages/Groups/Groups";
@import "../pages/Groups/TableGroups";
@import "../pages/History/History";

@import "../pages/Keys/Keys";

@import "../pages/GettingQRCode/GettingQRCode";
@import "../pages/Login/Login";
@import "../pages/Login/LoginForm/LoginForm";

@import "../pages/ManageKey/ManageKey";
@import "../pages/Profile/Profile";
@import "../pages/Search/Search";
@import "../pages/Settings/Fragments/OAuthLifeTimeControl/OAuthLifeTimeControl";
@import "../pages/Settings/Fragments/SessionControl/SessionsControl";
@import "../pages/Settings/Fragments/PasswordGeneratorControl/PasswordGeneratorControl";
@import "../pages/Settings/Fragments/Yubikey/Yubikey";
@import "../pages/Settings/Fragments/OTPAdmin/OTPAdmin";
@import "../pages/Settings/Settings";
@import "../pages/Settings/Customize/Customize";
@import "../pages/Users/TableUsers";
@import "../pages/Users/TableUsersLocal";
@import "../pages/Users/Users";
@import "../pages/ActivityLog/ActivityLog";

// .SSUIButton_Theme_primary[disabled] {
//   background-color: rgba(var(#{$prefix}blue-dark), .6);
// }

.FolderIcon {
  &_access {
    &_write {
      .if-header {
        fill: $green;
      }
    }

    &_read {
      .if-header {
        fill: $yellow;
      }
    }

    &_no_access {
      .if-header {
        fill: $red;
      }
    }
  }
}

// Margins
@include list-loop(".mt-", "margin-top");
@include list-loop(".mr-", "margin-right");
@include list-loop(".mb-", "margin-bottom");
@include list-loop(".ml-", "margin-left");

// Paddings
@include list-loop(".pt-", "padding-top");
@include list-loop(".pr-", "padding-right");
@include list-loop(".pb-", "padding-bottom");
@include list-loop(".pl-", "padding-left");
