.History {
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-height: calc(100% - 90px);
  overflow: hidden;
  padding: 5px 10px 0;
  width: 100%;

  &-Statistic {
    position: relative;
  }

  &-Loading {
    .LoadingContainer-Background {
      .LoadingContainer-Animate {
        top: 40vh;
      }
    }
  }

  &-Item {
    background-color: $gray-01;
    border-radius: 36px;
    display: flex;
    height: 52px;
    margin-bottom: 5px;
    padding: 8px 10px;
    position: relative;
    width: 100%;
  }

  &-Icon {
    align-items: center;
    display: flex;
    margin-right: 10px;
    width: 36px;
  }

  &-Circle {
    align-items: center;
    background-color: transparent;
    border-radius: 90%;
    display: flex;
    height: 36px;
    justify-content: center;
    width: 36px;

    &-Small{
      height: 22px;
      margin-right: 5px;
      width: 22px;

      svg {
        fill: $white !important;
        margin: 0 !important;
      }
    }
  }

  &-Type {
    align-items: center;
    display: flex;
    padding-right: 10px;
    width: 35px;

    svg {
      @include tablet {
        margin-bottom: 0;
      }
    }
  }

  &-Info {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 105px);
    @include tablet {
      flex-wrap: nowrap;
      width: calc(100% - 120px);
    }

    &-Date {
      display: none;
      @include tablet {
        display: block;
      }
    }

    &-Name {
      @include truncate(100%);
      margin-right: 8px;
      @include tablet {
        @include truncate(calc(55% - 100px));
      }

      &-Link {
        align-items: center;
        display: flex;

        &_isDelete {
          color: $black;
          pointer-events: none;
        }

        svg {
          fill: var(#{$prefix}blue-dark);
          margin-right: 5px;
        }
      }
    }

    &-Text {
      align-items: center;
      display: flex;
    }
  }

  &-Actions {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 0 5px;
    width: auto;

    @include tablet {
      margin-bottom: 0;
      padding: 0 10px;
      width: auto;
    }
  }

  &-UserName {
    color: var(#{$prefix}blue-dark);
    margin: 0 5px;
    @include truncate(100px);
  }

}

.Circle {
  &-Red {
    background-color: $red;
  }

  &-Green {
    background-color: $green;
  }

  &-Yellow {
    background-color: $yellow;
  }

  &-Blue {
    background-color: $blue-dark;
  }
}

.AsideContent-Total {
  .History-Circle {
    margin: 5px 10px 5px 0;

    svg {
      fill: $white;
      margin: 0;
    }
  }
}
