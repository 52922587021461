.FolderManagement {
  margin: 0 0 40px;
  position: relative;

  &-Header {
    align-items: center;
    background-color: $white;
    border-bottom: 1px solid $gray-03;
    display: flex;
    font-size: 12px;
    justify-content: flex-start;
    position: sticky;
    top: 54px;
    z-index: 2;

    &-Title {
      padding: 10px 0;
      padding-left: 35px;
      width: calc(100% - 60px);
      @include tablet {
        width: calc(100% - 336px);
      }
    }

    &-Description {
      display: none;
      padding: 10px 0;
      width: 145px;
      @include tablet {
        display: flex;
      }
    }

    &-Date {
      display: none;
      padding: 10px 0;
      width: 130px;
      @include tablet {
        display: flex;
      }
    }

    &-Keys {
      padding: 10px 0;
      width: 60px;
      @include tablet {
        width: 60px;
      }
    }
  }

  &-ContentTopActions {
    overflow: hidden;

    .FolderManagement-btn-filter {
      border: 1px solid $gray-04;
      border-radius: $border-radius;
      display: none;
      float: right;
      height: 35px;
      padding: 9px 8px 8px;
      transition: all 0.2s ease-in-out;
      width: 35px;

      &:hover {
        border: 1px solid var(#{$prefix}blue-dark);
        transition: all 0.2s ease-in-out;

        svg {
          fill: var(#{$prefix}blue-dark);
          transition: all 0.2s ease-in-out;
        }
      }

      &_active {
        background-color: var(#{$prefix}blue-dark);
        border: 1px solid var(#{$prefix}blue-dark);

        &:hover {
          svg {
            fill: $white;
          }
        }

        svg {
          fill: $white;
        }
      }

      svg {
        transition: all 0.2s ease-in-out;
      }

      @include widescreen {
        display: block;
        margin-bottom: 0;
      }
    }
  }

  &-MobileMenu {
    display: block;
    margin-bottom: 4px;
    @include flex-container(row, space-between);

    @include widescreen {
      display: none;
      margin-bottom: 0;
    }

    .FolderManagement-btn-filter {
      border: 1px solid $gray-04;
      border-radius: $border-radius;
      float: right;
      height: 35px;
      padding: 9px 8px 8px;
      transition: all 0.2s ease-in-out;
      width: 35px;

      &:hover {
        border: 1px solid var(#{$prefix}blue-dark);
        transition: all 0.2s ease-in-out;

        svg {
          fill: var(#{$prefix}blue-dark);
          transition: all 0.2s ease-in-out;
        }
      }

      &_active {
        background-color: var(#{$prefix}blue-dark);
        border: 1px solid var(#{$prefix}blue-dark);

        &:hover {
          svg {
            fill: $white;
          }
        }

        svg {
          fill: $white;
        }
      }

      svg {
        transition: all 0.2s ease-in-out;
      }
    }

    @include widescreen {
      display: none;
      margin-bottom: 0;
    }
  }

  .Mobile-In {
    display: block;
    @include tablet {
      display: none;
    }
  }

  .Mobile-Out {
    display: none;
    @include tablet {
      display: block;
    }
  }

  &-Action {
    margin-top: 20px;

    @include flex-container(row, flex-start);
  }
}

.Table-Description-Truncate {
  @include truncate(100%);
}
