.Modal {
  &-Cropper {
    max-width: 500px;
    min-height: 70vh;
    position: fixed;
    width: 90%;
    @include tablet {
      min-width: 700px;
    }

    &-Header {
      height: 55px;
      width: 100%;
    }

    &-Body {
      min-height: calc(70vh - 100px);
      padding: 0 20px;
      position: relative;

      &-Code {
        align-items: center;
        display: flex;
        justify-content: center;

        img{
          width: 80%;
        }
      }
    }

    &-Action {
      background-color: $gray-01;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 10px 20px;
      width: 100%;
    }

    &-Slider {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 15px;
      width: 100%;

      p {
        color: $gray-03;
        font-size: 30px;
        line-height: 25px;
        margin: 0 10px;
        padding-bottom: 4px;
      }

      .SliderInput {
        appearance: none;
        height: 25px;
        max-width: 300px;
        width: 100%;

        &:focus {
          outline: none;
        }

        &::-webkit-slider-runnable-track {
          background: $gray-03;
          border-radius: 2px;
          cursor: pointer;
          height: 4px;
          width: 100%;
        }

        &::-webkit-slider-thumb {
          appearance: none;
          background: var(#{$prefix}blue-dark);
          border-radius: 25px;
          cursor: pointer;
          height: 18px;
          margin-top: -7px;
          width: 18px;
        }

        &::-moz-range-track {
          background: $gray-03;
          border-radius: 2px;
          cursor: pointer;
          height: 4px;
          width: 100%;
        }

        &::-moz-range-thumb {
          background: var(#{$prefix}blue-dark);
          border-radius: 25px;
          cursor: pointer;
          height: 18px;
          width: 18px;
        }

        &::-ms-track {
          background: transparent;
          border-color: transparent;
          color: transparent;
          cursor: pointer;
          height: 4px;
          width: 100%;
        }

        &::-ms-fill-lower {
          background: var(#{$prefix}blue-dark);
          border-radius: 2px;
        }

        &::-ms-fill-upper {
          background: var(#{$prefix}blue-dark);
          border-radius: 2px;
        }

        &::-ms-thumb {
          background: var(#{$prefix}blue-dark);
          border-radius: 25px;
          cursor: pointer;
          height: 18px;
          margin-top: 1px;
          width: 18px;
        }
      }
    }
  }
}
