.ErrorBoundary {
  display: flex;
  height: auto;
  justify-content: center;
  padding: 15vh 20px 0;
  width: 100%;
  @include tablet {
    margin: 0;
    padding: 15vh 0 0;
  }

  &-Box {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: column;
    height: fit-content;
    max-width: 570px;
    width: 100%;
  }

  &-Header {
    color: $black;
    font-size: 18px;
    font-weight: bold;
    padding: 15px 24px 5px;
    text-align: center;
    @include tablet {
      text-align: left;
    }

    p {
      margin-bottom: 10px;
    }
  }

  &-Icon {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 24px 30px 32px;
    width: 100%;
    @include tablet {
      justify-content: flex-start;
    }

    p {
      color: $black;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 0;
      text-align: center;
      @include tablet {
        text-align: left;
      }
    }
  }

  &-Warning {
    margin-bottom: 20px;
    margin-right: 5px;
    @include tablet {
      margin-bottom: 0;
      margin-right: 30px;
    }

    span {
      left: -4px;
      position: absolute;
      top: 14px;
    }
  }

  &-Action {
    background-color: $gray-01;
    display: flex;
    justify-content: center;
    padding: 18px 10px;

  }
}
