.OAuthLifeTimeControl {

  margin: 0 0 15px;

  label {
    display: block;
    font-size: 12px;
    margin: 10px 0 5px;
  }

  &-Header {
    align-items: center;
    display: flex;
    font-size: rem(14px);
    font-weight: $font-weight-bold;
    justify-content: space-between;
    min-height: 32px;
  }

  &-Hint {
    color: $gray-04;
    font-size: rem(12px);
    margin-bottom: 15px;
  }

  &-Select {
    max-width: 300px;
  }

}
