$square-animate: 50px;

.WithLoading {
  position: relative;
  z-index: 1;

  &-Background {
    @extend %transition;
    background: rgba(255, 255, 255, 0.7);
    border-radius: $border-radius-lg;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    z-index: $zlayer-8;
  }

  &-Animate {
    display: inline-block;
    height: $square-animate;
    width: $square-animate;
    z-index: $zlayer-9;

    @extend %middle-vertical-horizontal;

    &::after {
      animation: AnimateRing 1.2s linear infinite;
      border: 4px solid var(#{$prefix}blue-dark);
      border-color: var(#{$prefix}blue-dark) transparent var(#{$prefix}blue-dark) transparent;
      border-radius: 50%;
      content: " ";
      display: block;
      height: $square-animate;
      width: $square-animate;
    }

    svg {
      @extend %middle-vertical-horizontal;

      fill: var(#{$prefix}blue-dark);
    }
  }
}

@keyframes AnimateRing {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
