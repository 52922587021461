.Messages {
  color: $black;
  display: block;
  margin: 5px 0 20px;

  &-Error {
    color: $red;
  }

  &-Success {
    color: $green;
  }
}

// Style for "Error text"
