.Single {
  &_No_access {
    filter: grayscale(100%);
  }

  &-Folder {

    height: 50px;

    &:hover {
      background: var(#{$prefix}blue-01);
    }

    &_isOpen {
      background: var(#{$prefix}blue-01);
      cursor: default;
      font-weight: bold;
    }

    @include flex-container(row, flex-start);
    @extend %transition;
  }

  &-Back {
    display: flex;
    margin: 0 11px;
    padding: 0;
  }

  &-Space {
    display: inline-block;
    height: 1px;
    width: 36px;
  }

  .FolderIcon {
    margin: 0 10px 0 0;
    min-width: 30px;
  }

  &-Title {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-Count {
    color: $gray-04;
    font-size: rem(10px);
    font-weight: $font-weight-normal;
  }
}
