.Footer {
  background: var(#{$prefix}gray-02);
  box-sizing: border-box;
  width: 100%;

  &-Content {
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1920px;
    padding: 0 20px;
    width: 100%;
    @include tablet {
      height: 60px;
      padding: 0 20px;
    }
    @include fullhd {
      height: 70px;
      padding: 0 40px;
    }
  }

  &-Info{
    .copywriting {
      color: $gray-04;
      font-size: rem(12px);
      font-weight: 100;
    }

    p{
      align-items: center;
      color: $black;
      display: flex;
      margin: 0;

      span{
        color: $gray-04;
        display: block;
        font-weight: 100;
        margin-left: 5px;
      }
    }
  }

  &-Download{
    align-items: center;
    display: flex;
    justify-content: flex-end;

    &-Text{
      color: $black;
      display: none;
      font-size: 14px;
      font-weight: normal;
      margin: 0;
      @include desktop {
        display: block;
        margin-right: 5px;
      }

      a{
        margin: 0 3px;
      }
    }

    &-Link{
      align-items: center;
      display: flex;

      a{
        margin-left: 15px;
        @include desktop {
          margin-left: 10px;
        }
      }
    }
  }
}
