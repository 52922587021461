.Breadcrumbs {
  display: none;
  margin: 0;
  max-width: calc(100% - 90px);
  min-height: 20px;
  overflow: auto !important;
  width: 100%;
  @extend %scrollbar;

  &::-webkit-scrollbar {
    background-color: rgba($gray-01, 0.3);
    border-radius: 4px;
    height: 3px;
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($gray-02, 0.5);
    border-radius: 4px;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  @include tablet {
    @include flex-container(row, flex-start);
  }

  a {
    font-size: rem(12px);
  }

  &-Flex {
    align-items: center;
    display: flex;
    max-width: 250px;

    &:nth-child(2) {
      .Breadcrumbs-Delimiter {
        display: none;
      }
    }

    a{
      @include truncate(100%);
    }
  }

  &-Root {
    @include flex-container(row, flex-start);
    background-color: $white;
    height: 20px;
    left: 0;
    margin: 0;
    margin-right: 5px;
    position: sticky;

    &Icon {
      @include flex-container();
      height: 21px;
      margin: 0;
      text-align: center;
      width: 21px;
      @include tablet {
        margin: 0 10px 0 0;
      }
    }

    svg {
      fill: var(#{$prefix}blue-dark);
    }

    .RootIcon {
      height: 100%;
    }

    &Username {
      font-weight: $font-weight-bold;
    }
  }

  &-Delimiter {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 4px 0 0;
    padding-bottom: 2px;
    @include tablet {
      margin: 0 4px 0 0;
      padding-bottom: 0;
    }
  }

  &-Item {
    margin: 0 5px 0 0;
  }

  &-Last {
    display: block;
    font-size: rem(14px);
    font-weight: $font-weight-bold;
    @include truncate(100%);
  }
}
