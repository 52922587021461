.InfoBoxGroupMembers {
  min-height: 300px;

  &-Modal {
    box-sizing: border-box;
    height: auto;
    left: calc(50% - 10px);
    margin: 10px;
    max-height: 95vh;
    min-height: 70vh;
    overflow: auto;
    padding: 16px 0 20px;
    position: fixed;
    width: calc(100% - 20px);

    @include tablet {
      max-width: 450px;
      padding: 16px 0 30px;
    }

    &-Header {
      align-items: center;
      display: flex;
      margin-bottom: 20px;
      padding: 0 20px;
      width: 100%;
      @include tablet {
        padding: 0 20px;
      }

      p {
        font-size: 18px;
        font-weight: bold;
        margin: 0;
        margin-left: 15px;
        @include truncate(calc(100% - 60px));
      }

      svg {
        fill: var(#{$prefix}blue-dark);
        min-width: 30px;
      }
    }

    &-Action {
      align-items: center;
      background-color: $gray-01;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      padding: 20px;
      position: absolute;
      width: 100%;
    }

    &-List {
      max-height: 70vh;
      min-height: 50vh;
      overflow: auto;
      padding: 0 5px 80px 20px;
      width: 100%;
      @extend %scrollbar;
      @include tablet {
        padding: 0 10px 80px 20px;
      }

      .Field {
        margin: 0;
      }

      &-Header {
        margin: 10px 0 15px;
        padding: 0 20px;
        @include tablet {
          padding: 0 20px;
        }
      }

      &-Item {
        display: flex;
        margin-bottom: 10px;
        position: relative;
        width: 100%;
      }

      &-Icon {
        align-items: center;
        display: flex;
        height: 25px;
        justify-content: flex-end;
        min-width: 25px;

        svg {
          fill: var(#{$prefix}blue-dark);
        }
      }

      &-User {
        display: flex;
        flex-direction: column;
        height: 25px;
        justify-content: center;
        padding-left: 10px;
        width: 100%;

        p {
          font-size: 14px;
          font-weight: bold;
          margin: 0;
          @include truncate(100%);
        }

        span {
          font-size: 10px;
        }
      }
    }
  }

  &-Header {
    font-size: rem(14px);
    margin: 0 0 10px;
    padding-left: 10px;
  }

  &-List {
    @extend %scrollbar;
    max-height: 260px;
    min-height: 260px;
    overflow: auto;
    padding-left: 8px;
    padding-top: 15px;

    &-Item {
      p {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 0;
        @include truncate(100%);
      }
    }
  }

  &-Search {
    .SearchInput {
      &-Wrap {
        width: 100%;
      }

      &-Icon {
        height: 16px;
        left: 10px;
        top: 50%;
        width: 16px;

        svg {
          height: 16px;
          width: 16px;
        }
      }

      &-Input {
        background-color: $white;
        height: 38px;
        padding-left: 36px;
        width: 100%;
      }

      &-Button {
        height: 16px;
        width: 16px;

        svg {
          height: 16px;
          width: 16px;
        }
      }
    }
  }

  &-UsersList {
    .label {
      font-size: rem(14px);
      margin-bottom: 10px;
    }
  }

  &-Empty {
    font-size: rem(14px);
    margin-bottom: 10px;
  }

  &-Error {
    color: $red;
    margin-bottom: 10px;
  }

  &-UserItem {
    margin-bottom: 10px;
    white-space: nowrap;

    .name {
      flex: 1;
      font-weight: $font-weight-bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    svg {
      fill: var(#{$prefix}blue-dark);
      margin-right: 10px;
    }

    .role {
      margin-right: 10px;
      min-width: 95px;
    }

    @include flex-container(row, space-between);
  }

  &-Delete {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 0;

    &:hover {
      svg {
        fill: $red;
      }
    }

    svg {
      fill: $gray-03;
      margin-right: 0;
      @extend %transition;
    }
  }

  select {
    background-color: $white;
    border: 1px solid transparent;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    font-size: rem(14px);
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    width: 100%;
  }

  &-Button {
    &Add {
      font-size: rem(14px);
      font-weight: $font-weight-bold;
      padding: 0 0 0 6px !important;
    }
  }

  &-Submit {
    bottom: 20px;
    left: 20px;
    position: absolute;
  }
}
