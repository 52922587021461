.InstallPassword {
  background: $white;
  border-radius: $border-radius-lg;
  box-shadow: $box-shadow;
  max-width: 320px;
  min-width: 320px;
  position: relative;

  &-FieldsContainer {
    padding: 15px 18px 0;

    .Field {
      &-Checkbox {
        align-items: center;
        flex-direction: row-reverse;
        justify-content: flex-end;
        margin-bottom: 20px;

        .Label {
          font-size: rem(14px);
          margin: 0 0 0 10px;
          padding-top: 3px;
        }
      }
    }

    @include mobile {
      padding: 15px 18px 0;
    }
  }

  &-FormHeader {
    align-items: center;
    display: flex;
    padding: 15px 18px;

    p {
      color: $black;
      font-size: rem(16px);
      font-weight: $font-weight-bold;
      margin: 0;
    }

    svg {
      margin-right: 15px;
    }
  }

  &-ButtonContainer {
    background-color: $gray-01;
    border-radius: 0 0 $border-radius-lg $border-radius-lg;
    padding: 16px 18px;
    width: 100%;

    @include mobile {
      padding: 16px 18px;
    }
  }

  &-ButtonSubmit {
    .Button-Text {
      align-items: center;
      display: flex;
      font-size: 14px;

      svg {
        margin-right: 10px;
      }
    }
  }

  &-FieldGroup {
    margin: 0 0 15px;
    position: relative;

    .Messages-Error {
      bottom: -18px;
      font-size: rem(12px);
      margin: 0;
      position: absolute;
      right: 6px;
    }

    .Field {
      // margin: 0;

      // &:nth-child(1) {
      //   input {
      //     border-radius: $border-radius $border-radius 0 0;
      //   }
      // }

      // &:nth-child(2) {
      //   margin-top: -1px;

      //   input {
      //     border-radius: 0 0 $border-radius $border-radius;
      //     padding-right: 35px;
      //   }
      // }

      &-Input {
        // &_hasError {
        //   input {
        //     border: 1px solid $red !important;
        //   }
        // }

        input {
          border: 1px solid var(#{$prefix}blue-dark);
        }
      }
    }

    &Input {
      &Flex {
        .Label {
          align-items: center;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }

  .username {
    display: block;
    font-size: rem(16px);
    padding: 0 18px;
  }

  .invalid-token {
    color: $red;
    display: block;
    font-size: rem(16px);
    padding: 0 18px;
  }
}
