.ActivityLog {
  min-height: calc(100% - 90px);

  &-Retention {
    @include flex-container(row, flex-start, center, center);
  }

  .Table-Head-Th {
    &:nth-child(1) {
      padding-left: 0;
      width: 70px;
      @include tablet {
        padding-left: 10px;
        width: 115px;
      }
    }

    &:nth-child(2) {
      display: none;
      @include tablet {
        display: flex;
        padding-right: 0;
        width: 55px;
      }
    }

    &:nth-child(3) {
      display: none;
      @include tablet {
        display: flex;
        width: calc(100% - 470px);
      }
    }

    &:nth-child(4) {
      width: 150px;
      @include desktop {
        width: 150px;
      }
    }

    &:nth-child(5) {
      width: 115px;
      @include desktop {
        width: 115px;
      }
    }

  }

  .Table-Table-Th {
    padding: 5px 10px;

    &:nth-child(1) {
      font-size: 12px;
      font-weight: bold;
      padding-left: 0;
      width: 70px;
      @include tablet {
        padding-left: 10px;
        width: 115px;
      }
    }

    &:nth-child(2) {
      display: none;
      @include tablet {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 55px;
      }
    }

    &:nth-child(3) {
      display: none;
      @include tablet {
        display: flex;
        width: calc(100% - 470px);
        @include truncate(100%);
      }
    }

    &:nth-child(4) {
      font-size: 12px;
      width: 150px;
      @include desktop {
        width: 150px;
      }
    }

    &:nth-child(5) {
      width: 150px;
      @include desktop {
        width: 150px;
      }
    }

  }

  &-Table {
    .icon {
      @include middle-inline-block();

      svg {
        fill: var(#{$prefix}blue-dark);
      }
    }

    .object {
      @include middle-inline-block();
    }

    .user-role {
      color: $gray-04;
      font-size: rem(12px);
      line-height: 14px;
    }

    .username {
      color: var(#{$prefix}blue-dark);
      display: block;
      @include truncate(100%);
    }
  }
}
