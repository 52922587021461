.TreeList {
  .EmptyFolderStatus {
    width: 36px;
  }

  &-Folder {
    @include flex-container(row, flex-start);

    height: 40px;

    &:hover {
      background: var(#{$prefix}blue-01);
    }

    &_chosen {
      background: var(#{$prefix}blue-01);
      font-weight: bold;
    }
  }

  .FolderStatus {
    margin: 0 10px;
  }

  .FolderIcon {
    margin: 0 10px 0 0;
    min-width: 30px;
  }

  &-Title {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex: 1;
    padding-right: 15px;
    width: 100px;

    &-Truncate {
      @include truncate(100%);
    }

    button {
      display: flex;
    }
  }

  &-Count {
    color: $gray-04;
    font-size: rem(10px);
    font-weight: $font-weight-normal;
  }

  &-Favorites {
    svg{
      fill: var(#{$prefix}blue-dark);
    }
  }

  &-Keys {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    min-width: 66px;

    &-Item {
      align-items: center;
      background-color: $white;
      border: 1px solid var(#{$prefix}blue-dark);
      border-radius: 6px;
      display: flex;
      margin: 0 3px;
      padding: 4px;

      svg {
        fill: var(#{$prefix}blue-dark);
        height: 14px;
        margin-right: 5px;
        width: 14px;
      }

      span {
        color: var(#{$prefix}blue-dark);
        font-size: 12px;
      }
    }
  }

  &-Date {
    color: $gray-04;
    display: none;
    font-size: 12px;
    @include tablet {
      align-items: center;
      display: flex;
      width: 125px;
    }
  }

  &-Description {
    align-items: center;
    color: $gray-04;
    display: none;
    font-size: 12px;
    justify-content: flex-start;
    padding-right: 20px;
    width: 145px;
    @include tablet {
      display: flex;
    }

    &-Truncate {
      @include truncate(100%);

      p {
        display: inline;
        margin: 0;
      }
    }
  }

  @for $i from 1 through 10 {
    &_level_#{$i} {
      @extend %tree-level;
      margin: 0 0 0 18px;
    }
  }
}
