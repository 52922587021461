.TableFolderManagement {
  .Checkbox {
    margin-bottom: 2px;
  }

  &-Favorite {
    align-items: center;
    display: flex;
    height: 100%;
    margin-left: 5px;
    padding-top: 5px;
  }

  .Table {
    &-Th {
      align-items: center;
      display: flex;

      &:first-child {
        max-width: 25px;
        width: 100%;
        @include tablet {
          max-width: 36px;
        }
      }

      &:nth-child(2) {
        width: calc(100% - 100px);
        @include tablet {
          width: calc(50% - 66px);
        }
      }

      &:nth-child(3) {
        display: none;
        @include tablet {
          display: flex;
          width: 25%;
        }
      }

      &:nth-child(4) {
        display: none;
        @include tablet {
          display: flex;
          width: calc(25% - 45px);
        }
      }

      &:last-child {
        justify-content: center;
        width: 75px;
      }
    }
  }

  &-Title {
    background: transparent;

    &_level {
      @for $i from 1 through 10 {
        &_#{$i} {
          $padding: 0 + (20px * ($i - 1));

          margin-left: $padding !important;
        }
      }
    }

    .FolderIcon {
      margin-right: 10px;
    }

    .title {
      white-space: nowrap;
    }

    @include flex-container(row, flex-start);
  }
}
