.InfoBoxFolder {
  .Label {
    margin-left: 0;
  }

  &-Info {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &-Item {
      p {
        font-size: rem(12px);
        margin-bottom: 5px;
      }

      span {
        font-size: rem(12px);
        font-weight: $font-weight-bold;
      }

    }
  }

  &-Textarea {
    &_isDisable {
      .Field-Textarea-RichButton, .DraftEditor-root {
        background-color: #F2F2F2;
        filter: grayscale(100%);
      }

      .Field-Textarea-RichButton {
        pointer-events: none;
      }
    }
  }

  &-Favorites {
    padding: 15px 0 0;
    width: 100%;

    &-Button {
      align-items: center;
      display: flex;
      height: 26px;
      padding: 0;

      span {
        color: var(#{$prefix}blue-dark);
        display: inline-block;
        font-weight: $font-weight-bold;
        padding-left: 10px;
      }
    }
  }

  .Field-Parent {
    .Field-Select {
      z-index: 1;
      @include widescreen {
        z-index: 1;
      }
    }
  }
}
