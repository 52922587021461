.hide-mobile {
  display: none;
}

@include tablet {
  .hide-mobile {
    display: inherit;
  }

  .hide-tablet {
    display: none;
  }
}

@include desktop {
  .hide-tablet {
    display: inherit;
  }
}
