@mixin font-face(
  $font-family,
  $file-path,
  $weight: normal,
  $style: normal,
) {
  @font-face {
    font-display: swap;
    font-family: $font-family;
    font-style: $style;
    font-weight: $weight;

    src: url("#{$file-path}.eot");
    src: url("#{$file-path}.eot?#iefix") format("embedded-opentype"),
    url("#{$file-path}.woff")       format("woff"),
    url("#{$file-path}.ttf")        format("truetype");
  }
}
