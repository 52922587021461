.Tree {
  &_No_access {
    filter: grayscale(100%);
  }

  .EmptyFolderStatus {
    min-width: 36px;
  }

  &-Folder {
    @extend %transition;

    cursor: pointer;
    height: 50px;

    &:hover {
      background: var(#{$prefix}blue-01);
    }

    &_isActive {
      background: var(#{$prefix}blue-01);
      font-weight: bold;
    }

    .Tree {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
    }

    @include flex-container(row, flex-start);
  }

  .FolderStatus {
    margin: 0 10px;
  }

  .FolderIcon {
    cursor: pointer;
    margin: 0 10px 0 0;
    min-width: 30px;
  }

  &-Box {
    align-items: center;
    display: flex;
    overflow: hidden;
  }

  &-Title {
    cursor: pointer;
    flex: 1;
    max-width: calc(100% - 10px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-Count {
    color: $gray-04;
    font-size: rem(10px);
    font-weight: $font-weight-normal;
  }

  @for $i from 1 through 10 {
    &_level_#{$i} {
      min-width: 270px;
      @extend %tree-level;

      @include desktop {
        display: inline-block;
        width: 100%;
      }
    }
  }
}
