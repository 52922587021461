.InfoBoxKeySharing {
  &-Empty {
    font-size: rem(14px);
    margin-bottom: 10px;
  }

  &-Grayscale{
    filter: grayscale(100%);
  }

  select {
    background-color: $white;
    border: 1px solid transparent;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    font-size: rem(14px);
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    width: 100%;
  }

  &-Icon{
    fill: var(#{$prefix}blue-dark);
  }

  &-Header {
    font-size: rem(14px);
    margin: 0 0 10px;
  }

  &-ButtonAdd {
    margin: 0 !important;
    padding-left: 0 !important;
  }

  &-FoldersList {
    margin-top: 5px;
  }

  &-Folder {
    @include flex-container(row, space-between);
    margin-bottom: 15px;
    width: 100%;
  }

  &-Name {
    display: inline-block;
    font-size: rem(16px);
    margin: 0 0 0 10px;
    overflow: hidden;

    a {
      span{
        @include truncate(100%);
      }
    }
  }

  &-Delete {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 0;

    &:hover {
      svg {
        fill: $red;
      }
    }

    svg {
      fill: $gray-03;
      margin-right: 0;
      @extend %transition;
    }
  }

  &-Title {
    align-items: center;
    display: flex;
    width: calc(100% - 50px);

    a {
      display: flex;
    }
  }
}
