.ExpireWindow {
  min-width: 300px;
  padding: 20px;
  text-align: center;

  &-Attention {
    border: 2px solid $yellow;
    border-radius: 100%;
    color: $yellow;
    font-size: rem(50px);
    height: 75px;
    line-height: 75px;
    margin: 10px auto;
    width: 75px;
  }

  &-Done {
    align-items: center;
    border: 2px solid $green;
    border-radius: 100%;
    display: flex;
    height: 75px;
    justify-content: center;
    line-height: 75px;
    margin: 10px auto;
    width: 75px;
  }

  &-Text {
    font-size: rem(24px);
    margin: 0;
  }

  p {
    margin: 20px 0;
  }
}