.Settings {
  padding: 0 0 30px;

  &-Row {
    &-Name {
      width: 50%;

      &-Big {
        width: 70%;
      }
    }

    &-User {
      width: 25%;
    }

    &-Manager {
      width: 25%;
    }
  }

  &-Table {
    margin-bottom: 10px;
    max-width: 400px;
    width: 100%;

    &-Header {
      align-items: center;
      display: flex;
      font-size: 12px;
      font-weight: bold;
      height: 22px;

      &-Row {
        align-items: center;
        display: flex;
      }
    }

    &-Body {
      display: flex;
      flex-direction: column;

      &-Row {
        align-items: center;
        display: flex;
        font-size: 12px;
        height: 22px;
      }
    }
  }

  &-Container {
    background-color: transparent;
    border-radius: 6px;
    margin: 3px 0 6px;
    padding: 10px 18px;
    position: relative;

    &::after {
      border-bottom: 1px solid $gray-02;
      bottom: -3px;
      width: calc(100% - 36px);
      @include pseudo(block);
    }

    &:hover {
      background-color: $gray-01;

      .ContentPagination,
      .pagination {
        background-color: $gray-01;
        @extend %transition;
      }
    }

    &:last-child {
      &::after {
        display: none;
      }
    }

    @extend %transition;
  }

  &-Header {
    align-items: center;
    display: flex;
    font-size: rem(14px);
    font-weight: $font-weight-bold;
    justify-content: space-between;
    min-height: 32px;

    &-Left {
      justify-content: flex-start;

      .Settings-Switch {
        .Field-Switch {
          margin: 0 10px 0 0;
        }
      }
    }
  }

  &-ModalLabel {
    p {
      margin-bottom: 6px;
    }
  }

  &-ExportFolders {
    margin-top: 15px;

    p {
      margin-bottom: 10px;
    }

    &-List {
      margin-top: 10px;

      p {
        margin-bottom: 6px;
      }
    }
  }

  &-Checked {
    align-items: center;
    display: flex;

    p {
      margin: 0 0 0 10px;
    }

    &-SubFolder {
      align-items: center;
      display: flex;
      margin: 0 0 10px 10px;

      p {
        margin: 0 0 0 10px;
      }
    }
  }

  &-Switch {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;

    &-Row {
      align-items: flex-start;
      flex-direction: column;
      @include widescreen {
        align-items: center;
        flex-direction: row;
      }
    }

    .Field-Switch {
      margin-left: 5px;
    }

    p {
      font-size: 12px;
      margin-bottom: 0;
    }

    &-Disable {
      color: $gray-03;
    }

    &-Box {
      align-items: center;
      display: flex;
      min-height: 38px;
      min-width: 150px;
    }

    &-Right {
      align-items: center;
      display: flex;
      font-size: 12px;
      margin-bottom: 6px;

      &:has(.Checkbox > input[disabled]) {
        color: #a7a7a8;
      }

      p, label:last-child {
        margin: 0 0 0 8px;
      }
    }
  }

  &-SwitchItem {
    margin-bottom: 15px;
  }

  &-Hint {
    color: $gray-04;
    font-size: rem(12px);
    margin-bottom: 15px;
  }

  &-Icon {
    @include middle-inline-block();
    box-sizing: border-box;
    fill: var(#{$prefix}blue-dark);
    margin: 0 11px 0 0;

    &.import {
      transform: rotate(-90deg);
    }

    &.export {
      transform: rotate(90deg);
    }
  }

  &-ButtonGroup {
    display: flex;
    flex-direction: column;
    @include tablet {
      flex-direction: row;
    }
  }

  &-ButtonWrapper {
    margin: 0 20px 20px 0;
    position: relative;
    width: fit-content;
    @include tablet {
      margin: 0 20px 20px 0;
    }
  }

  &-Arrow {
    margin: -4px 0 0 15px;
    @include middle-inline-block();
    @extend %transition;
  }

  &-Options {
    background-color: $white;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    color: $black;
    left: 0;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 50px;
    width: 100%;
    z-index: 1;
  }

  &-Option {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-weight: $font-weight-base;
    padding: 10px;
    text-align: left;
    width: 100%;

    &:hover {
      background: $gray-01;
      font-weight: $font-weight-bold;
    }

    span {
      align-items: center;
      display: flex;

      svg {
        margin: 0 10px 0 0;
        min-width: 20px;
      }
    }

    @extend %transition;
  }

  &-Modal {
    max-width: 500px;
    min-width: 300px;
    width: calc(100% - 40px);

    sup {
      font-size: rem(12px);
      font-weight: $font-weight-base;
    }
  }

  &-ModalBody {
    margin: 10px 0 30px;
  }

  &-ModalSelect {
    margin: 5px 0 0;
    max-width: 100px;
  }

  &-ConveyanceFile {
    margin-bottom: 20px;

    .error {
      color: $red;
    }
  }

  &-ErrorArchive {
    color: $red;
    margin-top: -10px;
  }

  &-UploadField {
    border: 1px solid $gray-04;
    border-radius: $border-radius;
    flex: 1;
    overflow: hidden;
    padding: 10px 13px;
    position: relative;

    &Name {
      margin: 0 0 0 10px;
    }

    &Wrapper {
      @include flex-container(row, flex-start);

      margin: 5px 0;

      label {
        background: var(#{$prefix}blue-dark);
        border-radius: $border-radius;
        color: $white;
        cursor: pointer;
        font-size: rem(14px);
        margin: 0 0 0 13px;
        padding: 10px 20px;
      }
    }

    input {
      bottom: 0;
      cursor: pointer;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 200%;
    }
  }

  &-Action {
    @include flex-container(row, flex-start);
    justify-content: space-between;
  }

  .errorArchive {
    color: $red;
    margin-top: -10px;
  }

  &-Select {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    margin: 10px 15px 0 0;
    @include mobile {
      margin: 0 15px 0 0;
    }
    @include widescreen {
      margin: 0 15px 0 0;
    }

    label {
      font-size: 12px;
      margin: 0;
    }

    &-Box {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      margin-top: 10px;

      label {
        margin: 0 0 0 10px;
      }

      @include mobile {
        align-items: center;
        flex-direction: row;
      }
      @include widescreen {
        margin-left: 0;
        margin-top: 0;
      }
    }
  }

  &-SelectLogout {
    align-items: center;
    display: flex;

    label {
      font-size: 12px;
      margin: 0 0 0 10px;
    }
  }

  &-PassSelect {
    min-width: 100px;
  }

  &-LogoutTime {
    width: 120px;
  }

  &-EncryptedPassword {
    margin: 15px 0;

    .Field-Radio {
      margin: 0 10px 0 0;

      label {
        cursor: pointer;
        margin-bottom: 0;
      }
    }
  }

  @for $i from 1 through 10 {
    &_level_#{$i} {
      @extend %tree-level-export;
    }
  }

  &-Fields {
    @include flex-container(column, flex-start, flex-start, flex-start);
    margin-top: 20px;

    @include tablet() {
      flex-direction: row;
    }
  }

  &-StaticValue {
    width: 100%;

    &__block {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 45px;

      svg {
        cursor: pointer;
      }
    }

    &__label {
      color: $black;
      margin-bottom: 10px;
    }

    &__value {
      @include flex-container(row, flex-start, flex-start);
      background-color: $gray-01;
      border-radius: 6px;
      font-weight: bold;
      justify-content: space-between;
      max-width: 290px;
      min-height: 38px;
      min-width: 200px;
      padding: 10px 10px 10px 15px;
      word-break: break-all;

      button {
        display: flex;
        margin: 0 0 0 10px;
        padding: 0;
      }

      &--view {
        position: relative;

        &.inputClose {
          &::before {
            background-color: $gray-03;
            content: "";
            height: 2px;
            position: absolute;
            right: 1px;
            top: 6px;
            transform: rotate(-45deg);
            width: 18px;
          }
        }
      }
    }
  }

  &-Field {
    &__label {
      color: $black;
      margin: 10px 0;
    }

    &__value {
      @include flex-container(column, flex-start, flex-start);

      @include mobile {
        @include flex-container(row, flex-start, flex-start);
      }

      .action {
        align-items: center;
        display: flex;
      }

    }

    &__password {
      margin-bottom: 15px;
      margin-right: 20px;
      position: relative;

      @include mobile {
        margin-bottom: 0;
      }

      &--block {
        align-items: center;
        display: flex;
        justify-content: space-between;
        position: absolute;
        right: 10px;
        top: 12px;
        width: 45px;

        svg {
          cursor: pointer;
          fill: var(#{$prefix}blue-dark);
        }
      }

      input {
        border: 0;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        height: 38px;
        min-width: 290px;
        outline: none;
        padding: 5px 60px 5px 10px;

        &:focus {
          outline: none;
        }
      }

    }
  }

  &-GenerateBackupPassword {
    margin-right: 10px;
  }

  &-AutoBackup {
    flex-direction: column;
    margin-top: 10px;

  }

  &-AutoBackupPassword {
    &_error {
      border: 1px solid $red !important;
    }

    &_fieldError {
      color: $red;
      display: block;
      margin-top: -10px;
    }
  }

  &-BashCode {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    span {
      display: block;
      font-size: 12px;
      margin-bottom: 5px;
    }

    &-Warning {
      color: $red;
      font-size: 12px;
      margin: 5px 0;
    }

    &-Header {
      font-weight: bold;
    }

    &-Code {
      width: 100%;
    }

    &-Actions {
      align-items: center;
      display: flex;
      height: fit-content;

      svg {
        cursor: pointer;
        fill: var(#{$prefix}blue-dark);
        margin: 0 0 0 7px;
      }
    }

    &-Item {
      border-radius: 6px;
      box-shadow: 0 5px 15px rgba($black, .25);
      display: flex;
      font-weight: bold;
      padding: 12px;
      word-break: break-all;
    }

    p {
      background-color: $gray-01;
      border-radius: 6px;
      margin: 0;
      padding: 10px;
      word-break: break-all;
    }

  }

  &-Warning {
    align-items: center;
    background: #feddde;
    border-radius: 6px;
    display: flex;
    font-weight: $font-weight-bold;
    height: 40px;
    margin-bottom: 15px;
    padding: 5px 10px;

    svg {
      margin-right: 10px;
    }
  }

  &-Success {
    align-items: center;
    background: rgba($green, .4);
    border-radius: 6px;
    display: flex;
    font-weight: $font-weight-bold;
    height: 40px;
    margin-bottom: 15px;
    padding: 5px 10px;

    svg {
      margin-right: 10px;
    }
  }

}

.PassInput {
  input {
    padding-right: 40px;
  }

  .input {
    &::before {
      content: none;
    }
  }

  .password {
    &::before {
      background-color: var(#{$prefix}blue-dark);
      content: "";
      height: 2px;
      position: absolute;
      right: 9px;
      top: 18px;
      transform: rotate(-45deg);
      width: 22px;
    }
  }
}

.PassInputBlock{
  .iconBlock{
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .input {
    &::before {
      content: none;
    }
  }

  .password {
    &::before {
      background-color: var(#{$prefix}blue-dark);
      content: "";
      height: 2px;
      position: absolute;
      right: -2px;
      top: 6px;
      transform: rotate(-45deg);
      width: 22px;
    }
  }
}
