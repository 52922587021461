.Yubikey {
  &-List {
    margin: 0 0 20px;

    &Header {
      font-size: rem(12px);
      font-weight: $font-weight-bold;
    }

    li {
      margin: 0 0 5px;
      white-space: nowrap;

      div {
        display: inline-block;
        padding: 0 10px 0 0;
        vertical-align: top;
        white-space: initial;

        &:nth-child(1) {
          width: 90%;

          @include mobile {
            width: 40%;
          }
        }

        &:nth-child(2) {
          display: none;

          @include mobile {
            display: inline-block;
            width: 25%;
          }
        }

        &:nth-child(3) {
          display: none;

          @include mobile {
            display: inline-block;
            width: 25%;
          }
        }

        &:nth-child(4) {
          width: 10%;
        }
      }

      svg {
        margin: 0 5px 0 0;
      }
    }

    .empty{
      font-size: 12px;
      margin-top: 10px;
    }

    //.left {
    //  @include flex-container(flex-start, flex-start);
    //  min-width: 150px;
    //
    //  svg {
    //    margin: 0 10px 0 0;
    //  }
    //}
    //
    //.right {
    //  @include flex-container(flex-start, flex-start);
    //
    //  .Checkbox {
    //    margin: 0 10px;
    //  }
    //}
  }
}
