.Modal {
  &-Header {
    align-items: center;
    display: flex;
    font-size: rem(18px);
    font-weight: $font-weight-bold;
    height: 50px;
    padding: 0 20px;

    svg {
      margin-right: 10px;
    }

    p {
      margin: 0 5px;
      @include truncate(calc(100% - 65px));
    }

    .FolderIcon {
      margin-right: 10px;

      svg {
        margin-right: auto;
      }

      span {
        margin: auto;
      }
    }
  }

  &-Vpn {
    min-height: 150px !important;

    svg {
      bottom: 75px;
      position: absolute;
      right: 40px;
    }

    h3{
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }

  &-Scroll {
    flex: 1;
    max-height: calc(80vh - 120px);
    min-height: auto;
    overflow: auto;
    padding: 16px 20px;
    width: 100%;
    @extend %scrollbar;
  }

  &-Errors {
    width: 100%;

    p {
      color: $red;
    }
  }

  &-Actions {
    background-color: $gray-01;
    border-radius: 0 0 $border-radius-lg $border-radius-lg;
    display: flex;
    justify-content: space-between;
    padding: 16px 20px;

  }

  &-Info {
    color: $gray-04;
    margin: 10px 0;
  }

  &-Search {
    margin: 0 0 10px;
    position: relative;
    width: 100%;

    &-Icon {
      left: 10px;
      position: absolute;
      top: calc(50% - 8px);
      width: auto;
    }

    label {
      display: inline-block;
      margin: 0 0 5px;
    }

    input {
      background-color: $gray-01;
      border: 1px solid transparent;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      display: block;
      height: 36px;
      outline: none;
      padding: 0 16px;
      padding-left: 36px;
      width: 100%;
      @extend %transition;

      &:focus {
        background-color: $white;
        @extend %transition;
      }
    }
  }

  &-Substrate {
    animation: modalOpen 0.2s ease-in-out;
    background: $box-shadow-color;
    bottom: 0;
    cursor: default !important;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: $zlayer-3;

    &_closeble {
      cursor: pointer !important;
    }

    &_indexHigh {
      z-index: $zlayer-4;
    }
  }

  &-Body {
    animation: modalOpen 0.2s ease-in-out;
    background: $white;
    border-radius: $border-radius-lg;
    left: 50%;
    min-height: 100px;
    min-width: 200px;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: $zlayer-4;
  }

  &-Azure-Go-App {
    align-items: center;
    background-image: url("#{$assetPath4}/png/bg-login.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 0;
    border-radius: 0;
    height: 100vh;
    left: 0;
    top: 0;
    transform: unset;
    width: 100%;

    a {
      align-items: center;
      background-color: var(#{$prefix}blue-dark);
      border-radius: 6px;
      color: $white;
      display: flex;
      font-weight: $font-weight-bold;
      height: 40px;
      justify-content: center;
      width: 100%;
    }

    &-Header {
      align-items: center;
      background-color: var(#{$prefix}blue-dark);
      color: $white;
      display: flex;
      font-size: 12px;
      font-weight: normal;
      height: 50px;
      justify-content: center;
      width: 100%;

      svg {
        fill: var(#{$prefix}blue-dark);
        margin-left: 5px;
      }
    }

    &-Icon {
      align-items: center;
      background: $white;
      border-radius: 20px;
      display: flex;
      height: 135px;
      margin-bottom: 50px;
      width: 135px;
    }

    &-Content {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      padding: 0 18px;
    }

    h2 {
      font-weight: $font-weight-bold;
      margin-bottom: 30px;
      text-align: center;
    }

    .Modal-Content {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .Modal-Close {
      top: 16px;

      svg {
        fill: $white;
      }
    }
  }

  &-Close {
    cursor: pointer;
    display: flex;

    fill: var(#{$prefix}blue-dark);
    position: absolute;
    right: 18px;
    top: 18px;

    &:hover {
      fill: $red;
    }

    @extend %transition;
  }

  &-Confirmation {
    max-width: 500px;
    min-width: 280px;
    position: fixed;
    top: 50vh;

    &-Small {
      .Modal-Content {
        overflow: hidden;
      }
    }

    &Text {
      font-size: rem(14px);
      margin-bottom: 15px;

      &.red {
        color: $red;
        font-size: rem(12px);
      }
    }

    &List {
      margin-bottom: 20px;
    }

    &Item {
      @include flex-container(row, flex-start);

      font-size: rem(14px);
      font-weight: $font-weight-bold;
      margin-bottom: 10px;
      word-break: break-all;

      &:last-child {
        margin-bottom: 0;
      }

      svg {
        fill: var(#{$prefix}blue-dark);
        margin-right: 15px;
        min-width: 20px;
      }

      span {
        margin-left: 15px;
        padding-top: 3px;
        word-break: break-all;
      }

      &Name {
        padding-top: 3px;
      }
    }

  }

  &-ConfirmationItemFolders {
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    width: 100%;

    &:hover {
      background-color: $gray-01;
      transition: all 0.4s ease-in-out;
    }

    &-Box {
      @for $i from 1 through 10 {
        &_level_#{$i} {
          @extend %tree-level-export;
        }
      }
    }

    &-Name {
      align-items: center;
      display: flex;
      max-width: calc(100% - 40px);
      width: 100%;
    }

    &-Info {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      padding: 8px;
      width: 100%;

      span {
        margin-left: 8px;
        @include truncate(calc(100% - 50px));
      }

      .Tree {
        .FolderStatus {
          margin-left: 0;
        }
      }

      .EmptyFolderStatus {
        background-color: $gray-03;
        border-radius: 3px;
        display: inline-block;
        height: 16px;
        margin: 0 10px 0 0;
        min-width: 16px;
        position: relative;
        vertical-align: middle;
        width: 16px;

        &::before {
          background: $white;
          content: "";
          display: block;
          height: 2px;
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 12px;
        }
      }
    }

    &-Remove {
      align-items: center;
      cursor: pointer;
      display: flex;
      justify-content: center;
    }

    &-Children {
      .Modal-ConfirmationItemFolders-Info {
        padding: 4px 8px;
      }
    }
  }

  &-Azure-Go-App {
    align-items: center;
    background-image: url("#{$assetPath4}/png/bg-login.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 0;
    border-radius: 0;
    height: 100vh;
    left: 0;
    top: 0;
    transform: unset;
    width: 100%;

    a {
      align-items: center;
      background-color: var(#{$prefix}blue-dark);
      border-radius: 6px;
      color: $white;
      display: flex;
      font-weight: $font-weight-bold;
      height: 40px;
      justify-content: center;
      max-width: 400px;
      width: 100%;
    }

    &-Header {
      align-items: center;
      background-color: var(#{$prefix}blue-dark);
      color: $white;
      display: flex;
      font-size: 12px;
      font-weight: normal;
      height: 50px;
      justify-content: center;
      width: 100%;

      svg {
        margin-left: 5px;
      }
    }

    &-Icon {
      align-items: center;
      background: #fff;
      border-radius: 20px;
      display: flex;
      height: 135px;
      margin-bottom: 50px;
      width: 135px;
    }

    &-Content {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      padding: 0 18px;
    }

    h2 {
      font-weight: $font-weight-bold;
      margin-bottom: 30px;
      text-align: center;
    }

    .Modal-Content {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .Modal-Close {
      top: 16px;

      svg {
        fill: $white;
      }
    }
  }

}
