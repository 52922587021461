.AsideContent {
  height: 100%;
  overflow: auto;
  padding: 0 20px;

  &-Header {
    align-items: center;
    background-color: $white;
    display: flex;
    margin-bottom: 10px;
    overflow: hidden;
    padding-bottom: 10px;
    padding-top: 15px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1;

    h2 {
      font-size: 16px;
      font-weight: $font-weight-bold;
      line-height: 16px;
      margin: 0;
      @include truncate(100%);
    }

    &-Folder {
      align-items: center;
      background-color: $white;
      display: flex;
      margin-bottom: 10px;
      padding-bottom: 10px;
      padding-top: 15px;
      position: sticky;
      top: 0;
      width: 100%;

      .FolderIcon {
        margin-right: 10px;
        min-width: 30px;
      }

      &-Info {
        overflow: hidden;
        width: 100%;

        h2 {
          font-size: 16px;
          font-weight: $font-weight-bold;
          line-height: 16px;
          margin: 0;
          @include truncate(100%);
        }
      }

      &-Button {
        align-items: center;
        color: var(#{$prefix}blue-dark);
        cursor: pointer;
        display: flex;
        height: 14px;
        padding-top: 3px;

        svg {
          cursor: pointer;
          fill: var(#{$prefix}blue-dark);
          margin-right: 5px;
        }

        span {
          font-size: 12px;
          line-height: 12px;
          padding-top: 2px;
        }
      }
    }
  }

  &-ReadMoreLink {
    color: var(#{$prefix}blue-dark);
    cursor: pointer;
    font-size: 12px;
    padding-left: 0;
  }

  &-Description {
    margin-bottom: 20px;

    p {
      font-size: 12px;
      margin: 0;
      width: 100%;
      word-break: break-word;

      ol, ul {
        padding-inline-start: 30px;
      }

      ul > li {
        list-style-type: disc;
      }
    }

  }

  &-Action {
    background-color: $white;
    bottom: 0;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    position: sticky;
    width: 100%;

    button {
      justify-content: center;
      margin-bottom: 10px;
      width: 100%;

      &:last-child {
        margin-bottom: 10px;
      }
    }
  }

  &-Favorite {
    align-items: center;
    display: flex;
    margin-bottom: 10px;
  }

  &-FolderInfo {
    align-items: center;
    display: flex;
    margin-bottom: 20px;
    width: 100%;

    &-Item {
      width: 50%;

      p {
        font-size: 12px;
        margin-bottom: 10px;
      }

      span {
        align-items: center;
        display: flex;
        font-size: 14px;
        font-weight: $font-weight-bold;
        margin-left: 5px;
      }
    }
  }

  &-Total {
    margin-bottom: 20px;

    p {
      font-size: 12px;
      margin-bottom: 8px;
    }

    span {
      align-items: center;
      display: flex;
      font-size: 14px;
      font-weight: $font-weight-bold;
      margin-left: 5px;
    }

    svg {
      fill: var(#{$prefix}blue-dark);
      margin-right: 5px;
      min-height: 14px;
    }

    &-Count {
      align-items: center;
      display: flex;
      font-size: 12px;

      p, span {
        line-height: 20px;
        margin: 0 5px 0 0;
      }

      span {
        color: var(#{$prefix}blue-dark);
      }

      &-Black {
        color: $black !important;
      }
    }

    &-Dot {
      svg {
        fill: var(#{$prefix}blue-dark);
      }
    }

    &-Status {
      align-items: center;
      background-color: $white;
      border: 1px solid var(#{$prefix}blue-dark);
      border-radius: 6px;
      display: flex;
      line-height: 10px;
      margin: 3px 0;
      padding: 3px;

      span {
        color: var(#{$prefix}blue-dark);
        font-size: 12px;
        font-weight: normal;
        line-height: 12px;
        margin-right: 0;
      }

      &-Count {
        margin-left: 10px !important;
      }

      &-0 {
        border: 1px solid $gray-03;

        span {
          color: $gray-03;
        }
      }

      &-2 {
        border: 1px solid $yellow;

        span {
          color: $yellow;
        }
      }
    }

    &-List {
      margin: 0;
      padding: 0;

      li {
        align-items: center;
        display: flex;
        font-weight: bold;
        margin-bottom: 5px;
        padding-left: 5px;

        svg {
          min-height: auto;
        }
      }
    }

    &-LastKey {
      align-items: center;
      display: flex;

      svg {
        min-width: 20px;
      }

      &-Info {
        color: var(#{$prefix}blue-dark);
        cursor: pointer;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: $font-weight-bold;
        justify-content: center;
        overflow: hidden;
        width: 100%;

        span {
          @include truncate(100%);
          display: block;
          margin: 0;
        }

        time {
          color: $gray-03;
          font-size: 10px;
          font-weight: 400;
        }
      }
    }
  }

  &-Limit {
    .label {
      font-size: rem(12px);
    }

    span {
      font-weight: $font-weight-bold;
    }

    a {
      margin: 0 0 0 5px;
    }

    svg {
      @include middle-inline-block();
      margin: 0 0 0 5px;
    }
  }
}
