.TableUsers {
  min-height: 40vh;

  &-Checkbox {
    display: none;
    @include desktop {
      display: flex;
    }
  }

  &-Icon {
    padding: 5px 0;

    svg {
      fill: var(#{$prefix}blue-dark);
    }
  }

  &-Title {
    align-items: center;
    display: flex;
    max-width: calc(100% - 90px);
    white-space: normal;
    width: 100%;

    &-Column {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      width: 100%;

      p {
        font-size: 14px;
        font-weight: $font-weight-bold;
        margin: 0;
        @include truncate(100%);
      }

      span {
        color: $gray-04;
        display: block;
        font-size: 12px;
        width: 100%;
        @include truncate(100%);
      }
    }

    @include tablet {
      width: calc(100% - 260px);
    }
  }

  &-Role {
    color: $gray-04;
    display: none;
    font-size: 12px;
    width: 120px;
    @include tablet {
      display: flex;
    }
  }

  &-Groups {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    width: 75px;

    &-Item {
      align-items: center;
      background-color: $white;
      border: 1px solid var(#{$prefix}blue-dark);
      border-radius: 6px;
      display: flex;
      margin: 0 3px;
      padding: 4px;

      &_isEmpty {
        border: 1px solid $gray-03;

        svg {
          fill: $gray-03 !important;
        }

        span {
          color: $gray-03 !important;
        }
      }

      svg {
        fill: var(#{$prefix}blue-dark);
        height: 14px;
        margin-right: 5px;
        width: 14px;
      }

      span {
        color: var(#{$prefix}blue-dark);
        font-size: 12px;
      }
    }
  }

  .Table-Head-Th {
    &:nth-child(1) {
      display: none;
      @include desktop {
        display: flex;
      }
    }

    &:nth-child(2) {
      padding-left: 36px;
      width: 100%;
      @include tablet {
        padding-left: 36px;
        width: calc(100% - 233px);
      }
    }

    &:nth-child(3) {
      display: none;
      width: 120px;
      @include tablet {
        display: flex;
      }
    }

    &:nth-child(4) {
      width: 75px;
    }
  }
}
