.Navigation {
  $self: &;
  margin: 0;
  min-height: 100%;
  min-width: 280px;
  padding: 0;
  width: auto;

  &-Logo {
    align-items: center;
    display: flex;
    height: 60px;
    padding: 0 10px;
    width: 320px;

    @include desktop {
      display: none;
    }

    svg {
      max-width: 280px;
      width: 100%;
    }

    img {
      max-height: 30px;
    }
  }

  .Loading {
    display: block;
    margin: 10px auto;
  }

  &-BottomLink {
    &::after {
      left: 50%;
      top: -10px;
    }
  }

  &-Top {
    width: 100%;

    .Folders {
      max-height: calc(100vh - 145px);
      min-height: 200px;
      overflow: auto;
      padding-bottom: 5px;
      position: relative;
      @extend %scrollbar;

      @include desktop{
        max-height: calc(100vh - 250px);
      }
    }
  }

  &-Bottom {
    background-color: $gray-01;
    border-radius: 6px 6px 0 0;
    width: 100%;

    &_Link {
      color: $gray-04;
      height: 40px;

      &:hover {
        color: $black;

        &::after {
          visibility: hidden;
        }

        svg {
          fill: var(#{$prefix}blue-dark);
        }
      }

      svg {
        margin: 0 15px;
        width: 20px;
        @extend %transition;
      }

      span {
        @extend %transition;
      }

      @include flex-container(row, flex-start);
      @extend %transition;
    }

    &_Close {
      display: flex;
      justify-content: space-around;
      padding: 0 20px;

      a {
        max-width: 23px;

        &:hover {
          &::after {
            visibility: visible !important;
          }
        }
      }

      span {
        display: none;
        @extend %transition;
      }

      svg {
        margin: 0;
        width: 16px;
        @extend %transition;
      }

      @extend %transition;
    }

    #{$self}-isActive {
      color: $black;
      font-weight: $font-weight-bold;

      svg {
        fill: var(#{$prefix}blue-dark);
      }
    }

    @extend %transition;

  }

  @include desktop {
    flex: 1;
    height: 100%;
    @include flex-container(column, space-between, flex-start, flex-start);
  }

}
