.InfoBoxKeySettings {
  &-Container {
    @include flex-container(column, flex-start, flex-start, flex-start);
    margin: 0 0 10px;
  }

  &-Label {
    font-size: rem(12px);
    margin-bottom: 5px;
  }

  &-Item {
    align-items: center;
    background-color: $gray-01;
    border-radius: 6px;
    display: flex;
    height: 40px;
    justify-content: space-between;
    padding: 0 10px 0 15px;
    width: 100%;

    &-Description {
      height: auto;
      min-height: 40px;
      padding: 10px 15px;

      p {
        margin: 0;
        max-height: 180px;
        overflow: auto;
        width: 100%;
        word-break: break-all;

        * {
          all: revert;
          margin: 2px 0;
        }
      }
    }

    &-Password {
      height: auto;
      min-height: 40px;
    }

    &-Relative {
      position: relative;
    }
  }

  &-Attached {
    align-items: center;
    background-color: $gray-01;
    border-radius: 6px;
    display: flex;
    height: 40px;
    justify-content: space-between;
    padding: 0 15px;
    width: 100%;

    &-Value {
      align-items: center;
      display: flex;
      width: 90%;

      svg {
        margin-right: 10px;
        min-width: 16px;
      }

      span {
        @include truncate(100%);
        font-weight: $font-weight-bold;
      }
    }

    &-Actions {
      @include flex-container(row, flex-end);

      .Action {
        padding: 0 5px;

        svg {
          height: 14px;
          width: 20px;
        }
      }
    }

    // svg{
    //   margin: 0 10px;
    // }

    // button{
    //   color: var(#{$prefix}blue-dark);
    //   @include truncate(230px);
    // }
  }

  &-Value {
    @include flex-container(flex-start, flex-start);

    font-size: rem(14px);
    font-weight: $font-weight-bold;
    width: calc(100% - 30px);

    & > svg {
      fill: var(#{$prefix}blue-dark);
      margin: 0 10px 0 0;
    }

    span {
      @include truncate(100%);
    }

    &-Small {
      width: calc(100% - 50px);
    }

    &-Password {
      height: auto;
      padding: 5px 0;
      width: calc(100% - 55px);

      span {
        display: block;
        overflow: unset;
        white-space: unset;
        word-wrap: break-word;
      }
    }
  }

  &-OTP {
    @include flex-container(row, flex-start);

    .code {
      font-size: rem(24px);
      font-weight: $font-weight-bold;
      letter-spacing: 4.97px;
      margin-right: 5px;
    }

    .circle {
      @include middle-inline-block();

      margin-right: 5px;

      path {
        @extend %transition;
      }
    }

    .second {
      @include middle-inline-block();
      color: $gray-03;
      font-size: rem(14px);
      font-weight: $font-weight-bold;

      min-width: 40px;
      text-align: right;
    }
  }

  &-Actions {
    @include flex-container(row, space-between);

    a {
      display: flex;
      max-height: 20px;
    }

    button {
      padding: 0;
    }

    &-Right {
      justify-content: flex-end;

      a, div, button {
        margin: 0 3px;
      }
    }

    svg {
      fill: var(#{$prefix}blue-dark);
    }
  }

  &-ShowPassword {
    display: flex;
    margin: 0;
    padding: 0;
  }

  .inputShow {
    &::before {
      content: none;
    }
  }

  .inputClose {
    position: relative;

    &::before {
      background-color: var(#{$prefix}blue-dark);
      content: "";
      height: 2px;
      position: absolute;
      right: -1px;
      top: 6px;
      transform: rotate(-45deg);
      width: 20px;
    }
  }

  &-ButtonFile {
    font-weight: bold;
    padding: 0;
  }

  .ReadMore {
    &-Link {
      color: var(#{$prefix}blue-dark);
      font-weight: $font-weight-bold;
    }
  }

  &-Image {
    align-items: center;
    background-color: $gray-01;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    width: 100%;

    img {
      height: 150px;
      object-fit: cover;
      width: 150px;
    }
  }

  &-Row {
    @include flex-container(flex-start, flex-start, flex-start, flex-start);

    & > div {
      flex: 1;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  &-CreditCardTypeIcon {
    @include flex-container();
  }
}

//.OpenImage {
//  margin-bottom: 10px;
//}
