.InfoBoxKeyMultiply {
  &-Item {
    align-items: center;
    color: $black;
    display: flex;
    font-size: 12px;
    font-weight: $font-weight-bold;
    line-height: 20px;
    margin: 0 0 5px;

    &:last-child {
      margin: 0;
    }

    svg {
      fill: var(#{$prefix}blue-dark);
      margin: 0 10px 0 0;
      min-width: 18px;
    }

    &-Name {
      @include truncate(100%);
    }
  }
}
