.KeysSearch {
  height: auto;
  min-height: calc(100% - 90px);
  width: 100%;
}

.SearchFolder {
  min-height: calc(100% - 90px);
  width: 100%;

  &-Checkbox {
    display: none;
    @include desktop {
      display: flex;
      min-width: 36px;
    }
  }

  &-Actions {
    svg {
      fill: var(#{$prefix}blue-dark);
    }
  }

  &-Type {
    display: flex;
    justify-content: center;
    max-width: 30px;
    padding-left: 0;
    padding-right: 0;
    width: 100%;

    img {
      border-radius: 4px;
      height: 25px;
      width: 25px;
    }
  }

  &-Keys {
    align-items: center;
    display: none;
    max-width: 85px;
    width: 100%;
    @include tablet {
      display: flex;
    }

    &-Item {
      align-items: center;
      background-color: $white;
      border: 1px solid var(#{$prefix}blue-dark);
      border-radius: 6px;
      color: var(#{$prefix}blue-dark);
      display: flex;
      font-size: 12px;
      margin: 0 3px;
      padding: 4px;

      svg {
        margin-right: 5px;
      }
    }
  }

  &-Title {
    align-items: center;
    display: flex;
    max-width: calc(100% - 60px);
    white-space: normal;
    width: 100%;

    &-Column {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      width: 100%;

      p {
        font-size: 14px;
        font-weight: $font-weight-bold;
        margin: 0;
        @include truncate(100%);
      }

      span {
        color: $gray-04;
        display: block;
        font-size: 12px;
        width: 100%;
        @include truncate(100%);

        a {
          color: $gray-04;
        }
      }
    }

    &-Icon {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      min-width: 90px;

      svg {
        margin: 0 4px;
      }
    }

    @include tablet {
      width: calc(100% - 210px);
    }

    @include desktop {
      width: calc(100% - 300px);
    }
  }

  &-Date {
    color: $gray-04;
    display: none;
    font-size: 12px;

    @include tablet {
      display: flex;
      width: 130px;
    }
  }

  .Table-Head-Th {
    &:nth-child(1) {
      display: none;
      @include desktop {
        display: flex;
        min-width: 36px;
      }
    }

    &:nth-child(2) {
      opacity: 0;
      @include desktop {
        display: flex;
        min-width: 40px;
        // opacity: 1;
        padding-left: 5px;
      }
    }

    &:nth-child(3) {
      padding-left: 0;
      width: 100%;
    }

    &:nth-child(4) {
      display: none;
      min-width: 125px;
      @include tablet {
        display: flex;
      }
    }

    &:nth-child(5) {
      display: none;
      min-width: 87px;
      @include tablet {
        display: flex;
      }
    }

    &:nth-child(6) {
      min-width: 70px;
    }
  }
}
