.Aside {
  width: 100%;

  &_side_left {
    @include desktop {
      bottom: inherit;
      display: block;
      left: inherit;
      margin: 40px 0 0 40px;
      max-width: 248px;
      min-width: 280px;
      position: relative;
      right: inherit;
      top: inherit;
    }

    @include desktop {
      padding-top: 15px;
    }

    @include fullhd {
      min-width: 345px;
    }
  }

  &_side_right {
    background: $white;
    height: 0;
    overflow: auto;
    padding: 0;
    position: absolute;
    top: -50px;
    width: 0;
    z-index: 10;
    @include widescreen {
      display: block;
      height: calc(100vh - 130px);
      margin: 0 0 0 20px;
      max-width: 340px;
      min-width: 340px;
      overflow: visible;
      padding-right: 20px;
      padding-top: 15px;
      position: sticky;
      top: 0;
      z-index: 2;
    }
    @include fullhd {
      height: calc(100vh - 150px);
      margin: 0 0 0 20px;
      max-width: 400px;
    }
  }

  &-LayoutMenu {
    background: $white;
    bottom: 0;
    height: auto;
    left: 0;
    margin: 0;
    min-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: 0;
    width: 0;
    z-index: 8;
    @extend %transition;

    &_isOpen {
      padding: 0;
      width: 100% !important;

      .Navigation {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: calc(100% - 60px);
      }

      @include desktop {
        padding: 0;
      }
    }

    @include desktop {
      height: calc(100vh - 150px);
      margin: 0 20px;
      overflow-x: visible;
      overflow-y: visible;
      position: sticky;
      top: 0;
    }
    @include fullhd {
      height: calc(100vh - 150px);
      margin: 0 15px 0 40px;
    }
  }
}
