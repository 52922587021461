.InfoBoxUserMultiply {
  &-Item {
    @include flex-container(row, flex-start);
    font-size: rem(14px);
    font-weight: $font-weight-bold;

    margin: 0 0 10px;

    &:last-child {
      margin: 0;
    }

    span {
      @include truncate(100%);
      display: block;
    }

    svg {
      fill: var(#{$prefix}blue-dark);
      margin: 0 10px 0 0;
      min-width: 25px;
    }
  }
}
