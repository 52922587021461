.Modal {
  &-ModalFolderSelect {
    max-height: 80vh;
    max-width: 95%;
    min-height: auto;
    min-width: 95%;
    overflow: hidden;
    position: fixed;
    @include tablet {
      max-width: 500px;
      min-width: 500px;
    }

    &-Checkbox {
      align-items: center;
      display: flex;
      margin-bottom: 10px;

      label {
        margin-right: 10px;
      }
    }

  }
}
