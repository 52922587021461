.Login {
  // TODO: Combine helper OTP and Forgot popup style
  &-Otp {
    position: relative;

    &-Open {
      background-color: var(#{$prefix}blue-dark);
      border-radius: $border-radius;
      left: 0;
      max-width: 300px;
      position: absolute;
      top: 35px;
      width: 100%;
      z-index: $zlayer-2;

      &::before {
        background-color: var(#{$prefix}blue-dark);
        content: "";
        height: 10px;
        position: absolute;
        right: 40px;
        top: -5px;
        transform: rotate(45deg);
        width: 10px;
        @include mobile {
          right: calc(50% - 5px);
        }
      }

      p {
        color: $white;
        line-height: 16px;
        margin: 0;
        padding: 15px;
        text-align: left;

        a {
          color: $white;
          font-weight: bold;
        }
      }

      @include mobile {
        left: auto;
        right: -34%;
        top: 35px;
      }
    }
  }

  &-ForgotHelp {
    background-color: var(#{$prefix}blue-dark);
    border-radius: $border-radius;
    left: 95%;
    max-width: 300px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    z-index: $zlayer-2;

    &::before {
      border-color: transparent var(#{$prefix}blue-dark) transparent transparent;
      border-style: solid;
      border-width: 5px 5px 5px 0;
      content: "";
      height: 0;
      left: -5px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      width: 0;
    }

    p {
      color: $white;
      line-height: 16px;
      margin: 0;
      padding: 15px;
      text-align: left;
      white-space: break-spaces;

      a {
        color: $white;
        font-weight: bold;
      }
    }
  }

  &-Form {
    background: $white;
    border-radius: $border-radius-lg;
    box-shadow: $box-shadow;
    max-width: 320px;
    min-width: 320px;
    position: relative;
  }

  &-Error {
    align-items: center;
    background-color: rgba($red, .1);
    color: $black;
    display: flex;
    font-size: rem(12px);
    margin: 0;
    padding: 10px 18px;

    svg {
      margin-right: 12px;
      min-width: 20px;
    }
  }

  &-FieldsContainer {
    padding: 15px 18px 0;

    .PassInput {
      .input {
        &::before {
          content: none;
        }
      }

      .password {
        &::before {
          background-color: var(#{$prefix}blue-dark);
          content: "";
          height: 2px;
          position: absolute;
          right: 9px;
          top: 18px;
          transform: rotate(-45deg);
          width: 22px;
        }
      }
    }

    .Field {
      &-Checkbox {
        align-items: center;
        flex-direction: row-reverse;
        justify-content: flex-end;
        margin-bottom: 20px;

        .Label {
          font-size: rem(14px);
          margin: 0 0 0 10px;
          padding-top: 3px;
        }
      }
    }

    @include mobile {
      padding: 15px 18px 0;
    }
  }

  &-FieldGroupInput_has-error {
    input{
      border: 1px solid $red !important;
    }

    .Field-InputWrapper-Password{
      border: 1px solid $red !important;

      input{
        border: 0 !important;
      }
    }
  }

  &-FormHeader {
    align-items: center;
    display: flex;
    padding: 15px 20px;

    p {
      color: $black;
      font-size: rem(16px);
      font-weight: $font-weight-bold;
      margin: 0;
    }

    svg {
      fill: var(#{$prefix}blue-dark);
      margin-right: 10px;
    }
  }

  &-ButtonContainer {
    background-color: $gray-01;
    border-radius: 0 0 $border-radius-lg $border-radius-lg;
    padding: 16px 18px;
    width: 100%;
  }

  &-Forgot {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    padding: 0 20px !important;
    position: relative;
    @include mobile {
      padding: 0 30px !important;
    }
  }

  &-FieldGroup {
    margin: 0 0 15px;
    position: relative;

    .Messages-Error {
      font-size: rem(12px);
      position: absolute;
      right: 6px;
      top: 2px;
    }

    .Field {
      margin: 0;

      &:nth-child(1) {
        input {
          border-radius: $border-radius $border-radius 0 0;
        }
      }

      &:nth-child(2) {
        margin-top: -1px;

        .Field-InputWrapper-Password{
          border-radius: 0 0 $border-radius $border-radius;
        }

        input {
          padding-right: 35px;
        }
      }

      &-Input {
        &_hasError {
          input {
            border: 1px solid $red !important;
          }
        }

        input {
          border: 1px solid var(#{$prefix}blue-dark);
        }
      }
    }

    &Input {
      &Flex {
        .Label {
          align-items: center;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }

  &-ActionDelimiter {
    @include flex-container();

    margin: 10px 0;

    .line {
      background: $gray-04;
      flex: 1;
      height: 1px;
    }

    .or {
      color: $gray-04;
      font-size: rem(12px);
      margin: 0 12px;
    }
  }
}
